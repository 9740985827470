/* eslint-disable class-methods-use-this */
import React, { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Colors, Popover, Icon } from '@blueprintjs/core';
import { ThemeContext } from '../../../../../context/ThemeProvider';
import './styles.scss';

function AnnualTotalReturn({ fundData }) {
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);
  const [redColor, setRedColor] = useState(theme === 'dark' ? Colors.RED5 : Colors.RED1);
  const [grayColor, setGrayColor] = useState(theme === 'dark' ? Colors.DARK_GRAY1 : Colors.LIGHT_GRAY4);
  const [sharpeExplanation, setSharpeExplanation] = useState('');

  useEffect(() => {
    setRedColor(theme === 'dark' ? Colors.RED5 : Colors.RED1);
    setGrayColor(theme === 'dark' ? Colors.DARK_GRAY1 : Colors.LIGHT_GRAY4);
  }, [theme]);

  useEffect(() => {
    if (fundData.sharpe_ratio < 1) {
      setSharpeExplanation('bad');
    } else if (fundData.sharpe_ratio > 0.999 && fundData.sharpe_ratio < 1.99) {
      setSharpeExplanation('acceptable');
    } else if (fundData.sharpe_ratio > 1.99 && fundData.sharpe_ratio < 3) {
      setSharpeExplanation('great');
    } else if (fundData.sharpe_ratio > 3) {
      setSharpeExplanation('excellent');
    }
  }, [fundData]);

  if (!fundData) {
    return <></>;
  }

  return (
    <div style={{ marginTop: '4px' }}>
      {fundData.period && (
        <i style={{ position: 'absolute' }}>
          ({fundData.period.replace('Last', t('Last')).replace('Years', t('Years')).replace('Since', t('Since'))})
        </i>
      )}
      <table className={`bp3-html-table summary-statistics-table table-${theme}`}>
        <thead>
          <tr>
            <th style={{ width: '40%' }}> </th>
            <th style={{ width: '14%' }}> </th>
            <th style={{ width: '40%' }}> </th>
            <th style={{ width: '6%' }}> </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {t('Average Annual Return')} <i>({t('From Daily Returns')})</i>
            </td>
            <td>
              <b style={{ color: fundData.average_annual_return_from_daily_returns < 0 ? redColor : 'inherit' }}>
                {fundData.average_annual_return_from_daily_returns}%
              </b>
            </td>
            <td>
              {t('Sharpe Ratio')} <i>({t('Using Risk Free Rate')})</i>
            </td>
            <td>
              <span
                style={{
                  display: 'flex',
                  color: fundData.sharpe_ratio < 1 ? redColor : 'inherit',
                }}
              >
                <b>{fundData.sharpe_ratio}&nbsp;</b>
                <Popover
                  interactionKind="hover"
                  minimal
                  content={
                    <div
                      key="sharpe-pop-over"
                      style={{ display: 'flex', justifyContent: 'flex-end', padding: '0.5rem' }}
                    >
                      <table className="bp3-html-table bp3-html-table-bordered">
                        <thead>
                          <tr>
                            <th>{t('Sharpe Ratio')}</th>
                            <th>{t('Classification')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr style={{ backgroundColor: sharpeExplanation === 'bad' ? grayColor : 'inherit' }}>
                            <td>{t('Less than')} 1</td>
                            <td>{t('Bad')}</td>
                          </tr>
                          <tr
                            style={{
                              backgroundColor: sharpeExplanation === 'acceptable' ? grayColor : 'inherit',
                            }}
                          >
                            <td>1 - 1.99</td>
                            <td>{t('Acceptable')}</td>
                          </tr>
                          <tr style={{ backgroundColor: sharpeExplanation === 'great' ? grayColor : 'inherit' }}>
                            <td>2 - 2.99</td>
                            <td>{t('Great')}</td>
                          </tr>
                          <tr
                            style={{
                              backgroundColor: sharpeExplanation === 'excellent' ? grayColor : 'inherit',
                            }}
                          >
                            <td>{t('More than')} 3</td>
                            <td>{t('Excellent')}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  }
                >
                  <Icon icon="info-sign" iconSize={15} style={{ paddingRight: '2px' }} />
                </Popover>
              </span>
            </td>
          </tr>
          <tr>
            <td>
              {t('Average Annual Return')} <i>({t('From Weekly Returns')})</i>
            </td>
            <td>
              <b style={{ color: fundData.average_annual_return_from_weekly_returns < 0 ? redColor : 'inherit' }}>
                {fundData.average_annual_return_from_weekly_returns}%
              </b>
            </td>
            <td>
              {t('Time Series Stability')}
              <i>
                {' '}
                (R<sup>2</sup>)
              </i>
            </td>
            <td>
              <b>{fundData.stability_of_timeseries}%</b>
            </td>
          </tr>
          <tr>
            <td>
              {t('Volatility')} <i>({t('From Daily Returns')})</i>
            </td>
            <td>
              <b>{fundData.annual_volatility}%</b>
            </td>
            <td>{t('Tail Ratio')}</td>
            <td>
              <b>{fundData.tail_ratio}</b>
            </td>
          </tr>
          <tr>
            <td>
              {t('Volatility')} <i>({t('From Weekly Returns')})</i>
            </td>
            <td>
              <b>{fundData.std_from_weekly_returns}%</b>
            </td>
            <td>{t('Maximum Drawdown')}</td>
            <td>
              <b>{fundData.maximum_drawdown}%</b>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default AnnualTotalReturn;
