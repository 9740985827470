import React, { useContext, useState, useEffect } from 'react';

import { Colors } from '@blueprintjs/core';
import { ThemeContext } from '../../../../context/ThemeProvider';
import Loader from '../../../../components/loader';
import UPlot from '../../../../components/Uplot';

const MiniChart = ({ data, yLabel }) => {
  // const { t: tFunds } = useTranslation('fundNames');
  const { theme } = useContext(ThemeContext);
  const [blueColor, setBlueColor] = useState(theme === 'dark' ? Colors.BLUE5 : Colors.BLUE1);
  const [redColor, setRedColor] = useState(Colors.RED1);
  const [chartData, setChartData] = useState([]);

  const addOpacity = (color, value) => {
    const opacity = Math.floor(0.1 * value * 255).toString(16);
    return color + opacity;
  };

  const opts = {
    label: data.name,
    width: 80,
    height: 40,
    axes: [
      {
        show: false,
        ticks: { show: false },
        grid: { show: false },
      },
      {
        show: false,
        ticks: { show: false },
        grid: { show: false },
      },
    ],
    scales: {
      x: {
        time: true,
      },
    },
    series: [
      {},
      {
        stroke: '#ffffff',
        width: 3,
        // fill: 'rgba(255,0,0,0.1)',
      },
      {
        stroke: redColor,
        fill: addOpacity(redColor, 1),
        width: 4,
        points: {
          space: 2,
        },
      },
    ],
    legend: {
      show: false,
    },
  };

  useEffect(() => {
    setBlueColor(theme === 'dark' ? Colors.BLUE1 : Colors.BLUE5);
    setRedColor(theme === 'dark' ? Colors.RED5 : Colors.RED1);
  }, [theme]);

  useEffect(() => {
    if (data && data.data) {
      setChartData([
        data.data.map((a) => Date.parse(a.x) / 1000),
        data.data.map((a) => a.y),
        data.data.map(() => null),
      ]);
    }
  }, [data]);

  if (typeof chartData[0] === 'undefined') {
    return <Loader />;
  }
  return (
    <div>
      <UPlot options={opts} data={chartData} key={`mini_chart_${theme}`} yLabel={'test'} responsive={false} />
    </div>
  );
};

export default React.memo(MiniChart);
