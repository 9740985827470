/* eslint-disable react/no-danger */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Row, Col, Grid } from 'react-flexbox-grid';
import { Card } from '@blueprintjs/core';
import Ticker from '../Ticker';
import IndexCard from '../IndexCard';
import PostPreview from '../PostPreview';
import Pagination from '../Pagination';
import Post from '../Post';
import styles from './styles.module.scss';

const BlogGrid = ({ posts, mbi10Data, ombData, pageCount, onPageChange, currentPage, loader, currentPost }) => {
  return (
    <>
      <Grid fluid className={styles.blogGridContainer}>
        <Row>
          <Col lg={12} className={styles.topCard}>
            <div>
              <Card className={styles.blogGridCard}>
                <Ticker />
                {!currentPost && (
                  <div className={`bp3-callout ${styles.lastArticlesCallout}`}>
                    <h4 className="bp3-heading">Последни статии</h4>
                  </div>
                )}
                <Row>
                  <Col lg={9}>
                    {!currentPost && (
                      <Row className={styles.newsRow}>
                        {posts.map((post) => (
                          <PostPreview post={post} key={post.id} loader={loader} />
                        ))}
                        <Pagination
                          currentPage={currentPage}
                          pageCount={pageCount}
                          onPageChange={(e) => {
                            onPageChange(e);
                          }}
                        />
                      </Row>
                    )}
                    {currentPost && (
                      <Row className={styles.newsRow}>
                        <Post post={currentPost} loader={loader} />
                      </Row>
                    )}
                  </Col>
                  <Col lg={3}>
                    <div className={styles.newsRow}>
                      <IndexCard data={mbi10Data} />
                      <IndexCard data={ombData} />
                    </div>
                  </Col>
                </Row>
              </Card>
            </div>
          </Col>
        </Row>
      </Grid>
    </>
  );
};

export default BlogGrid;
