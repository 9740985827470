/* eslint-disable react/no-danger */
/* eslint-disable class-methods-use-this */
import React, { useContext, useState, useEffect } from 'react';
import DOMPurify from 'dompurify';
import { Card, ButtonGroup, Tabs, Tab, Icon, Button } from '@blueprintjs/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListUl } from '@fortawesome/free-solid-svg-icons';
import Emitter from '../../../../services/emitter';

import styles from './styles.module.scss';
import { ThemeContext } from '../../../../context/ThemeProvider';

function HorizontalMenu({ data, onTabChange, activeTab }) {
  const { theme } = useContext(ThemeContext);
  const iconColor = theme === 'dark' ? '#a7b6c2' : '#5c7080';
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    Emitter.on('RESET_SEARCH_VALUE', () => {
      setSearchValue('');
    });

    return () => {
      Emitter.off('RESET_SEARCH_VALUE');
    };
  }, []);

  if (!data) {
    return <></>;
  }

  return (
    <Card className={styles.secondaryMenu}>
      <ButtonGroup className={styles.secondaryMenuButtonGroup}>
        <Tabs
          id="blogMenu"
          selectedTabId={activeTab}
          onChange={(e) => {
            onTabChange(e);
          }}
        >
          <Tab id="home" panelClassName="ember-panel">
            <FontAwesomeIcon
              style={{ paddingRight: '4px', color: iconColor, fontSize: '15px', marginBottom: '-1px' }}
              icon={faListUl}
            />
            Почетна
          </Tab>

          {data.map((category) => (
            <Tab id={category.id} panelClassName="ember-panel" key={category.id}>
              <FontAwesomeIcon
                style={{ paddingRight: '4px', color: iconColor, fontSize: '15px', marginBottom: '-1px' }}
                icon={faListUl}
              />
              {DOMPurify.sanitize(category.name)}
            </Tab>
          ))}
        </Tabs>
        <span style={{ display: 'inline-flex' }}>
          <div className="bp3-input-group">
            <input
              className="bp3-input"
              type="search"
              placeholder="Пребарај"
              dir="auto"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  Emitter.emit('SEARCH_POST', searchValue);
                }
              }}
            />
          </div>
          <Button
            minimal
            style={{ marginLeft: '5px' }}
            icon="search"
            onClick={() => Emitter.emit('SEARCH_POST', searchValue)}
          />
        </span>
      </ButtonGroup>
    </Card>
  );
}

export default React.memo(HorizontalMenu);
