/* eslint-disable max-len */
// import Calculator from './model/Calculator';

export function roundNumber(e, r) {
  const t = 10 ** r;
  const a = Math.round(e * t) / t;
  return a;
}

export function getInterestRate(_calc) {
  return parseFloat(_calc.interestRate) / 100;
}

export function generateInvestmentPeriodData(investment) {
  const _calc = { ...investment };

  const interestCompoundTypesMap = {
    weekly: 52,
    bi_weekly: 26,
    monthly: 12,
    quarterly: 4,
    semi_annually: 2,
    yearly: 1,
  };

  const additionPeriodInWeeks = interestCompoundTypesMap[_calc.additionPeriod];
  const interestCompoundTypeInWeeks = interestCompoundTypesMap[_calc.interestCompoundedType];

  _calc.interestRate = getInterestRate(_calc);
  _calc.termInYears = parseInt(_calc.termInYears, 10);
  _calc.periodData = {};

  _calc.periodData.year = [];
  _calc.periodData.annual_investment = [];
  _calc.periodData.cumulative_investment = [];
  _calc.periodData.value_initial_investment = [];
  _calc.periodData.value_addition = [];
  _calc.periodData.total_interest_earned = [];
  _calc.periodData.annual_interest = [];
  _calc.periodData.total_value_investments = [];

  for (let year = 0; year <= _calc.termInYears; year++) {
    _calc.periodData.year[year] = {};
    if (year === 0) {
      _calc.periodData.year[year].annual_investment = roundNumber(_calc.initialInvestment, 2);
      _calc.periodData.year[year].cumulative_investment = 0;
      _calc.periodData.year[year].value_initial_investment = _calc.initialInvestment;
      _calc.periodData.year[year].value_addition = 0;
      _calc.periodData.year[year].total_interest_earned = 0;
      _calc.periodData.year[year].annual_interest = 0;
      _calc.periodData.year[year].total_value_investments = 0;
      // eslint-disable-next-line no-continue
      continue;
    }

    // Refactor pending
    _calc.periodData.year[year].annual_investment = roundNumber(_calc.regularAddition * additionPeriodInWeeks, 0);
    // eslint-disable-next-line max-len, no-mixed-operators
    _calc.periodData.year[year].cumulative_investment = roundNumber(
      _calc.initialInvestment + _calc.regularAddition * additionPeriodInWeeks * year,
      0
    );
    // eslint-disable-next-line max-len, no-mixed-operators
    _calc.periodData.year[year].value_initial_investment = roundNumber(
      _calc.initialInvestment *
        (1 + _calc.interestRate / interestCompoundTypeInWeeks) ** (year * interestCompoundTypeInWeeks),
      0
    );
    // eslint-disable-next-line max-len, no-mixed-operators, no-restricted-properties
    _calc.periodData.year[year].value_addition = roundNumber(
      (_calc.regularAddition *
        (additionPeriodInWeeks / interestCompoundTypeInWeeks +
          (((additionPeriodInWeeks / interestCompoundTypeInWeeks - 1) / 2) * _calc.interestRate) /
            interestCompoundTypeInWeeks) *
        (Math.pow(1 + _calc.interestRate / interestCompoundTypeInWeeks, year * interestCompoundTypeInWeeks) - 1)) /
        (_calc.interestRate / interestCompoundTypeInWeeks),
      0
    );
    // eslint-disable-next-line max-len, no-mixed-operators
    _calc.periodData.year[year].total_interest_earned = roundNumber(
      _calc.periodData.year[year].value_addition +
        _calc.periodData.year[year].value_initial_investment -
        _calc.periodData.year[year].cumulative_investment,
      0
    );
    // eslint-disable-next-line max-len, no-mixed-operators
    _calc.periodData.year[year].annual_interest = roundNumber(
      _calc.periodData.year[year].total_interest_earned - _calc.periodData.year[year - 1].total_interest_earned,
      0
    );
    // eslint-disable-next-line max-len, no-mixed-operators
    _calc.periodData.year[year].total_value_investments = roundNumber(
      _calc.periodData.year[year].value_addition + _calc.periodData.year[year].value_initial_investment,
      0
    );
  }
  return _calc.periodData.year;
}

function generateChartData(investmentPeriodData, id, label) {
  const results = { id, label, data: [] };
  investmentPeriodData.forEach((value, key) => {
    if (key === 0) {
      return;
    }
    const presentDate = new Date();
    let year = new Date();
    year.setMonth(presentDate.getMonth() + 12 * key);
    year = year.getFullYear();
    results.data.push({ x: year, y: value.total_value_investments });
  });
  return results;
}

export function calculateInvestment(investment, id, label) {
  const _calc = { ...investment };
  const result = {};

  _calc.interestRate = getInterestRate(_calc);
  _calc.termInYears = parseInt(_calc.termInYears, 10);
  const interestCompoundTypesMap = {
    weekly: 52,
    bi_weekly: 26,
    monthly: 12,
    quarterly: 4,
    semi_annually: 2,
    yearly: 1,
  };

  const additionPeriodInWeeks = interestCompoundTypesMap[_calc.additionPeriod];
  const amountInvested = _calc.initialInvestment + _calc.regularAddition * additionPeriodInWeeks * _calc.termInYears;
  const interestCompoundTypeInWeeks = interestCompoundTypesMap[_calc.interestCompoundedType];
  const totalValueInitialInvestment =
    _calc.initialInvestment *
    (1 + _calc.interestRate / interestCompoundTypeInWeeks) ** (_calc.termInYears * interestCompoundTypeInWeeks);
  const totalValuePeriodicAddition =
    _calc.regularAddition *
    (additionPeriodInWeeks / interestCompoundTypeInWeeks +
      // eslint-disable-next-line max-len
      ((additionPeriodInWeeks / interestCompoundTypeInWeeks - 1) / 2) *
        (_calc.interestRate / interestCompoundTypeInWeeks)) *
    // eslint-disable-next-line max-len
    (((1 + _calc.interestRate / interestCompoundTypeInWeeks) ** (_calc.termInYears * interestCompoundTypeInWeeks) - 1) /
      (_calc.interestRate / interestCompoundTypeInWeeks));
  const totalInvestmentValue = totalValueInitialInvestment + totalValuePeriodicAddition;
  const totalInterestEarned = totalInvestmentValue - amountInvested;

  result.amount_invested = roundNumber(amountInvested, 0);
  result.total_value_initial_investment = roundNumber(totalValueInitialInvestment, 0);
  result.total_value_periodic_addition = roundNumber(totalValuePeriodicAddition, 0);
  result.total_value_investment = roundNumber(totalInvestmentValue, 0);
  result.total_interest_earned = roundNumber(totalInterestEarned, 0);
  result.investmentPeriodData = generateInvestmentPeriodData(investment);
  const chartData = generateChartData(result.investmentPeriodData, id, label);
  return { investmentTableData: result, chartData };
}

export function calculateInvestments(investmentCalculators) {
  const results = [];
  const _chartData = [];
  const cumulativeInvestmentTableData = [];
  let grandTotal = 0;
  let amountInvested = 0;
  investmentCalculators.forEach((value, key) => {
    const { investmentTableData, chartData } = calculateInvestment(value, key + 1, `Investment ${key + 1}`);
    results[key] = investmentTableData;
    cumulativeInvestmentTableData[key] = {
      id: key + 1,
      name: `Investment ${key + 1}`,
      amount_invested: investmentTableData.amount_invested,
      total_value_investment: investmentTableData.total_value_investment,
    };
    grandTotal += investmentTableData.total_value_investment;
    amountInvested += investmentTableData.amount_invested;
    _chartData.push(chartData);
  });
  grandTotal = roundNumber(grandTotal, 0);
  amountInvested = roundNumber(amountInvested, 0);

  cumulativeInvestmentTableData[cumulativeInvestmentTableData.length] = {
    name: 'Grand Total',
    amount_invested: amountInvested,
    total_value_investment: grandTotal,
  };
  return { tableData: results, chartData: _chartData, cumulativeInvestmentTableData };
}
