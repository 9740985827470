/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Colors } from '@blueprintjs/core';
import { useTable, useSortBy } from 'react-table';
import GlobalContext from '../../../../context/global';
import { BACKEND_API } from '../../../../global/environment';
import { ThemeContext } from '../../../../context/ThemeProvider';
import Loader from '../../../../components/loader';
import { formatDate } from '../../../../utils/Date';
import styles from './styles.module.scss';

function FundsOverview() {
  const { theme } = useContext(ThemeContext);
  const { t } = useTranslation();
  const { t: tFunds } = useTranslation('fundNames');
  const globalContext = useContext(GlobalContext);
  const iconColor = theme === 'dark' ? '#a7b6c2' : '#5c7080';
  const [fundsData, setFundsData] = useState([]);
  const [redColor, setRedColor] = useState(Colors.RED1);
  // const [greenColor, setGreenColor] = useState(Colors.GREEN1);

  useEffect(() => {
    setRedColor(theme === 'dark' ? Colors.RED5 : Colors.RED1);
    // setGreenColor(theme === 'dark' ? Colors.GREEN5 : Colors.GREEN1);
  }, [theme]);

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;
    const riskReturnUrl = `${BACKEND_API}fundsMeta/overview`;

    fetch(riskReturnUrl, { signal })
      .then((results) => results.json())
      .then((jsonData) => {
        const results = [];
        jsonData.forEach((fund, key) => {
          results.push({
            fundId: fund.id,
            name: fund.name,
            lastDate: formatDate(fund.lastDate),
            dailyPriceChange: fund['1D'].value,
            dailyPercentChange: fund['1D'].percent,
            price: fund.lastPrice,
            '3M': fund['3M'].percent,
            '6M': fund['6M'].percent,
            YTD: fund.YTD.percent,
            '1Y': fund['1Y'].percent,
            '3Y': fund['3Y'].percent,
            '5Y': fund['5Y'].percent,
            netValue: fund.net_value,
          });
        });
        setFundsData(results);
      })
      .catch(() => {});
    return function cleanup() {
      abortController.abort();
    };
  }, []);

  const data = React.useMemo(() => {
    if (fundsData[0]) {
      return fundsData;
    }
    return [];
  }, [fundsData]);

  const columns = React.useMemo(
    () => [
      {
        Header: t('Name'),
        accessor: 'name',
      },
      {
        Header: `${t('Daily')} +/-`,
        accessor: 'dailyPriceChange',
        sortType: 'basic',
      },
      {
        Header: `${t('Daily')} %`,
        accessor: 'dailyPercentChange',
        sortType: 'basic',
      },
      {
        Header: t('Unit Price'),
        accessor: 'price',
        sortType: 'basic',
      },
      {
        Header: `3 ${t('Months')}`,
        accessor: '3M',
        sortType: 'basic',
      },
      {
        Header: `6 ${t('Months')}`,
        accessor: '6M',
        sortType: 'basic',
      },
      {
        Header: t('Year To Date'),
        accessor: 'YTD',
        sortType: 'basic',
      },
      {
        Header: `1 ${t('Year')}`,
        accessor: '1Y',
        sortType: 'basic',
      },
      {
        Header: `3 ${t('Years')}`,
        accessor: '3Y',
        sortType: 'basic',
      },
      {
        Header: `5 ${t('Years')}`,
        accessor: '5Y',
        sortType: 'basic',
      },
      {
        Header: t('Net Value'),
        accessor: 'netValue',
        sortType: 'basic',
      },
      {
        Header: t('Date Updated'),
        accessor: 'lastDate',
      },
    ],
    [t]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data }, useSortBy);

  const columnsWithoutFormatting = ['name', 'lastDate', 'price', 'netValue'];
  const getCellStyle = (cellInfo) => {
    if (columnsWithoutFormatting.includes(cellInfo.column.id)) {
      return {};
    }

    let { value } = cellInfo;
    if (typeof value === 'string') {
      value = value.replace('%', '');
    }

    return {
      color: !isNaN(value) && value < 0 ? redColor : 'inherit',
    };
  };

  const columnsWithPercent = ['dailyPercentChange', '3M', '6M', 'YTD', '1Y', '3Y', '5Y'];
  const columnsWithLocale = ['price', 'netValue'];
  const getCellFormatting = (cellInfo) => {
    let { value } = cellInfo;

    // eslint-disable-next-line no-compare-neg-zero
    if (value === -0) {
      value = 0;
    }

    if (columnsWithPercent.includes(cellInfo.column.id)) {
      return `${value.toLocaleString('en-US')}%`;
    }

    if (columnsWithLocale.includes(cellInfo.column.id)) {
      return value.toLocaleString('en-US');
    }

    if (cellInfo.column.id === 'name') {
      return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a href="#" onClick={() => globalContext.setFundAnalysisProperty('selectedFund', cellInfo.row.original.fundId)}>
          {tFunds(value)}
        </a>
      );
    }
    return value;
  };

  if (!fundsData[0]) {
    return <Loader />;
  }

  return (
    <table
      {...getTableProps()}
      className="bp3-html-table bp3-html-table-striped bp3-html-table-bordered"
      style={{ width: '100%' }}
    >
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                className={styles.disableSelection}
                style={{
                  borderBottom: column.isSorted ? 'solid 1px black' : 'inherit',
                  //   background: 'aliceblue',
                  //   color: 'black',
                  fontWeight: 'bold',
                }}
              >
                {column.render('Header')}
                <span>
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <Icon icon="chevron-down" iconSize={15} style={{ paddingLeft: '8px', color: iconColor }} />
                    ) : (
                      <Icon icon="chevron-up" iconSize={15} style={{ paddingLeft: '8px', color: iconColor }} />
                    )
                  ) : (
                    <Icon icon="expand-all" iconSize={15} style={{ paddingLeft: '8px', color: iconColor }} />
                  )}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <td
                    {...cell.getCellProps()}
                    style={{
                      padding: '10px',
                      ...getCellStyle(cell),
                    }}
                  >
                    {/* {cell.render('Cell')} */}
                    {getCellFormatting(cell)}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default FundsOverview;
