import React, { useContext, useState, useEffect } from 'react';
import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import GlobalContext from '../../context/global';
import HorizontalMenu from './components/HorizontalMenu';
import AnalysisGrid from './components/AnalysisGrid';

const FundAnalysis = () => {
  const globalContext = useContext(GlobalContext);
  const [activeTab, setActiveTab] = useState('overview');

  useEffect(() => {
    if (globalContext.fundAnalysisData.selectedFund < 1) {
      const tabs = ['risk', 'generalIndicators', 'netValue', 'overview'];
      if (!tabs.includes(activeTab)) {
        setActiveTab('overview');
      }
    } else {
      const tabs = ['general', 'price', 'anomaly', 'simulation'];
      if (!tabs.includes(activeTab)) {
        setActiveTab('general');
      }
    }
  }, [globalContext.fundAnalysisData.selectedFund]);

  return (
    <>
      <Header />
      <div style={{ backgroundColor: '#2b3a4a', height: '60px', width: '100%' }}>
        <HorizontalMenu
          activeTab={activeTab}
          activeFund={globalContext.fundAnalysisData.selectedFund}
          onTabChange={setActiveTab}
        />
      </div>
      <AnalysisGrid activeTab={activeTab} />
      <Footer />
    </>
  );
};

export default FundAnalysis;
