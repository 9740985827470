/* eslint-disable class-methods-use-this */
import React, { useEffect, useState, useContext } from 'react';
import { Colors } from '@blueprintjs/core';
import { ThemeContext } from '../../context/ThemeProvider';

// import styles from './styles.module.scss';

function InlineBar({ value }) {
  const { theme } = useContext(ThemeContext);
  const [marginLeft, setMarginLeft] = useState(0);
  const [barWidth, setBarWidth] = useState(Math.abs(value));
  const [redColor, setRedColor] = useState(Colors.RED1);
  const [greenColor, setGreenColor] = useState(Colors.GREEN1);

  useEffect(() => {
    setRedColor(theme === 'dark' ? Colors.RED5 : Colors.RED1);
    setGreenColor(theme === 'dark' ? Colors.GREEN5 : Colors.GREEN1);
    setBarWidth(Math.abs(value));
    if (value > 0) {
      setMarginLeft(50);
    } else {
      setMarginLeft(50 - barWidth);
    }
  }, [value, theme, barWidth]);

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', marginLeft: `${marginLeft}%`, width: '100%' }}>
        {value < 0 && (
          <div style={{ backgroundColor: redColor, width: `${Math.abs(barWidth)}%`, height: '20px' }}>&nbsp;</div>
        )}
        <div
          style={{
            borderLeft: '3px solid darkgray',
            height: '25px',
            marginLeft: '-3px',
            width: '3px',
            margin: '0px 1px 0px 1px',
          }}
        >
          &nbsp;
        </div>
        {value > 0 && <div style={{ backgroundColor: greenColor, width: `${barWidth}%`, height: '20px' }}>&nbsp;</div>}
      </div>
    </>
  );
}

export default InlineBar;
