import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Intent, Button, Classes, Dialog } from '@blueprintjs/core';

function setToasterCookie() {
  Cookies.set('cookieConsentDisplayed', true, { expires: 30 });
}

export function CookieInfoDialog({ isCookiesDialogOpen, setIsCookiesDialogOpen }) {
  const { t } = useTranslation();
  const state = {
    autoFocus: true,
    canEscapeKeyClose: true,
    canOutsideClickClose: true,
    enforceFocus: true,
    isOpen: isCookiesDialogOpen,
    onClose: () => setIsCookiesDialogOpen(false),
    usePortal: true,
  };
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Dialog icon="info-sign" title={t('cookie_policy_title')} {...state}>
      <div className={Classes.DIALOG_BODY}>
        <h4>{t('what_are_cookies')}</h4>
        <p>{t('what_is_cookie_text')}</p>
        <p>{t('what_is_cookie_text_additional')}</p>
        <p>{t('cookie_types_text')}</p>
        <ul>
          <li>{t('cookies_user_preferences')}</li>
          <li>{t('cookies_statistics_and_analytics')}</li>
          <li>{t('cookies_third_party')}</li>
        </ul>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button
            intent={Intent.PRIMARY}
            onClick={() => {
              setIsCookiesDialogOpen(false);
            }}
          >
            {t('close')}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

function ToasterMessage() {
  const [isCookiesDialogOpen, setIsCookiesDialogOpen] = useState(false);
  const { t } = useTranslation();
  return (
    <div>
      {t('cookies_consent')}
      <br />
      <br />
      {isCookiesDialogOpen && <CookieInfoDialog isCookiesDialogOpen setIsCookiesDialogOpen={setIsCookiesDialogOpen} />}
      <Button
        intent={Intent.PRIMARY}
        onClick={() => {
          setIsCookiesDialogOpen(true);
        }}
        text={<b>{t('more_information')}</b>}
      />
    </div>
  );
}
function useCookiesToaster(toaster) {
  // Would need some refactor
  const isEmbeded = window.location.search.toLowerCase().includes('embeded=true');

  const { t } = useTranslation();
  useEffect(() => {
    if (Cookies.get('cookieConsentDisplayed') !== 'true' && !isEmbeded) {
      toaster.show({
        action: {
          onClick: () => {
            setToasterCookie();
          },
          text: t('i_agree'),
        },
        intent: Intent.PRIMARY,
        timeout: 0,
        icon: 'info-sign',
        onDismiss: () => {
          setToasterCookie();
        },
        message: <ToasterMessage />,
      });
    }
  }, [t('i_agree')]);
}
export default useCookiesToaster;
