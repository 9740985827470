class Calculator {
  constructor(calculator) {
    this.name = calculator.name || '';
    this.initialInvestment = calculator.initialInvestment || 0;
    this.regularAddition = calculator.regularAddition || 0;
    this.interestRate = calculator.interestRate || 3;
    this.additionPeriod = calculator.additionPeriod || 'monthly';
    this.termInYears = calculator.termInYears || 10;
    this.interestCompoundedType = calculator.interestCompoundedType || 'yearly';
    this.selected = calculator.selected || true;
  }
}

export default Calculator;
