import { useState } from 'react';

export default () => {
  const [posts, setPosts] = useState([]);
  const [currentPost, setCurrentPost] = useState(null);
  const [categories, setCategories] = useState([]);
  return {
    categories,
    posts,
    setCategories,
    setPosts,
    currentPost,
    setCurrentPost,
  };
};
