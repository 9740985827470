import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import ReactGA from 'react-ga';
import { BottomLeftToaster } from './components/Toaster';
import useCookiesToaster from './hooks/useCookiesToaster';
import { HOME_PAGE, OPEN_END_FUND_ANALYSIS, BLOG } from './global/routes';
import FundAnalysis from './pages/FundAnalysis';
import HomePage from './pages/HomePage';
import Blog from './pages/Blog';
import GlobalProvider from './context/GlobalProvider';
import { ThemeProvider } from './context/ThemeProvider';
import { BlogProvider } from './context/BlogProvider';
import Theme from './components/Theme';

const App = () => {
  useEffect(() => {
    ReactGA.initialize('UA-184086953-1', {
      debug: false,
      gaOptions: {
        iteSpeedSampleRate: 100,
      },
    });
  }, []);
  useCookiesToaster(BottomLeftToaster);
  return (
    <Router>
      <GlobalProvider>
        <BlogProvider>
          <ThemeProvider>
            <Theme>
              <Route exact path={OPEN_END_FUND_ANALYSIS} component={FundAnalysis} />
              <Route exact path={HOME_PAGE} component={HomePage} />
              <Route exact path={BLOG} component={Blog} />
              <Route exact path={`${BLOG}/post/:slug`} component={Blog} />
            </Theme>
          </ThemeProvider>
        </BlogProvider>
      </GlobalProvider>
    </Router>
  );
};
export default App;
