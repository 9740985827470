/* eslint-disable class-methods-use-this */
import React, { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Colors } from '@blueprintjs/core';
import { ThemeContext } from '../../../../../context/ThemeProvider';
import { formatDate } from '../../../../../utils/Date';
import './styles.scss';

function SimulationSummaryStatistics({ simulationData }) {
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);
  const [redColor, setRedColor] = useState(theme === 'dark' ? Colors.RED5 : Colors.RED1);
  useEffect(() => {
    setRedColor(theme === 'dark' ? Colors.RED5 : Colors.RED1);
  }, [theme]);

  if (!simulationData.global_statistics) {
    return <></>;
  }

  return (
    <div>
      <p>
        <i>
          ({simulationData.n_iterations} {t('itterations for')} {simulationData.n_intervals} {t('intervals')} /{' '}
          {t('generated at')} {formatDate(simulationData.generated_date)})
        </i>
      </p>
      <table className={`bp3-html-table summary-statistics-table table-${theme}`}>
        {/* {JSON.stringify(simulationData)} */}
        <thead>
          <tr>
            <th style={{ width: '35%' }}> </th>
            <th style={{ width: '22%' }}> </th>
            <th style={{ width: '37%' }}> </th>
            <th style={{ width: '6%' }}> </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{t('Maximum Price')}</td>
            <td>
              <b style={{ color: simulationData.global_statistics.max_price < 0 ? redColor : 'inherit' }}>
                {simulationData.global_statistics.max_price}
              </b>
            </td>
            <td>
              {t('Maximum Price')} ({t('As Percentage Change')})
            </td>
            <td>
              <b>{simulationData.global_statistics.max_percent}%</b>
            </td>
          </tr>
          <tr>
            <td>{t('Minimum Price')}</td>
            <td>
              <b style={{ color: simulationData.global_statistics.min_price < 0 ? redColor : 'inherit' }}>
                {simulationData.global_statistics.min_price}
              </b>
            </td>
            <td>
              {t('Minimum Price')} ({t('As Percentage Change')})
            </td>
            <td>
              <b style={{ color: simulationData.global_statistics.min_percent < 0 ? redColor : 'inherit' }}>
                {simulationData.global_statistics.min_percent}%
              </b>
            </td>
          </tr>
          <tr>
            <td>{t('Average Price')}</td>
            <td>
              <b>{simulationData.global_statistics.mean_price}</b>
            </td>
            <td>
              {t('Average Price')} ({t('As Percentage Change')})
            </td>
            <td>
              <b>{simulationData.global_statistics.mean_percent}%</b>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default SimulationSummaryStatistics;
