import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { BACKEND_API } from './global/environment';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      Date: 'Date',
      Investment: 'Investment',
      'Investment Calculator': 'Investment Calculator',
      oif_analysis: 'Open End Fund Analysis',
      oif_analysis_short: 'Fund Analysis',
      'All Funds': 'All Funds',
      'Investment Configuration': 'Investment Configuration',
      'Initial Investment': 'Initial Investment',
      'Periodic Contribution': 'Periodic Contribution',
      'Initial value you want to invest': 'Initial value you want to invest',
      'How much do you want to invest each period': 'How much do you want to invest each period',
      'Contribution Interval': 'Contribution Interval',
      'Interval for periodic contributions': 'Interval for periodic contributions',
      'Expected Return': 'Expected Return',
      'Expected return in percent': 'Expected return in percent',
      'Term (In Years)': 'Term (In Years)',
      Compounding: 'Compounding',
      'How long you want to invest': 'How long you want to invest',
      'Compounding type': 'Compounding type',
      Weekly: 'Weekly',
      'Bi-weekly': 'Bi-weekly',
      Monthly: 'Monthly',
      Quarterly: 'Quarterly',
      'Semi-annually': 'Semi-annually',
      Yearly: 'Yearly',
      'Investment Flow': 'Investment Flow',
      Period: 'Period',
      Value: 'Value',
      'Investment Flow Details': 'Investment Flow Details',
      'Annual investment': 'Annual investment',
      Year: 'Year',
      year_long: 'Year',
      Years: 'Years',
      start: 'Start',
      'Cumulative Investment': 'Cumulative Investment',
      'Annual Interest': 'Annual Interest',
      'Total Interest Earned': 'Total Interest Earned',
      'Initial Investment Value': 'Initial Investment Value',
      'Contribution Value': 'Contribution Value',
      'Total Investment Value': 'Total Investment Value',
      difference_in_percent: 'Difference (In %)',
      'Portfolio Summary': 'Portfolio Summary',
      'Grand Total': 'Grand Total',
      Overview: 'Overview',
      'Risk Analysis': 'Risk Analysis',
      'Price Anomalies': 'Price Anomalies',
      'Net Value': 'Net Value',
      'Net Value (All Funds)': 'Net Value (All Funds)',
      MKD: 'MKD',
      'Risk/Return Chart': 'Risk/Return Chart',
      show_cluster_groups: 'Show Cluster Groups (experimental)',
      'Volatility (STD % From Weekly Returns)': 'Volatility (STD % From Weekly Returns)',
      'Average Annual Return (From Weekly Returns)': 'Average Annual Return (From Weekly Returns)',
      Name: 'Name',
      Daily: 'Daily',
      'Unit Price': 'Unit Price',
      Months: 'Months',
      months_long: 'Months',
      'Year To Date': 'Year To Date',
      'Date Updated': 'Date Updated',
      'Funds Overview': 'Funds Overview',
      'General Info': 'General Info',
      'Price Analysis': 'Price Analysis',
      Simulations: 'Simulations',
      'Since Inception': 'Since Inception',
      Summary: 'Summary',
      'Risk Class': 'Risk Class',
      'Average Annual Return': 'Average Annual Return',
      'From Daily Returns': 'From Daily Returns',
      'From Weekly Returns': 'From Weekly Returns',
      Volatility: 'Volatility',
      'Sharpe Ratio': 'Sharpe Ratio',
      'Using Risk Free Rate': 'Using Risk Free Rate',
      'Time Series Stability': 'Time Series Stability',
      'Tail Ratio': 'Tail Ratio',
      'Maximum Drawdown': 'Maximum Drawdown',
      'Annual Total Return': 'Annual Total Return',
      'Total Return': 'Total Return',
      'Hypothetical Growth Of': 'Hypothetical Growth Of',
      Classification: 'Classification',
      'Less than': 'Less than',
      Bad: 'Bad',
      Acceptable: 'Acceptable',
      Great: 'Good',
      Excellent: 'Excellent',
      'More than': 'More than',
      Last: 'Last',
      Since: 'Since',
      'Anomalies Graph (Experimental)': 'Anomalies Graph (Experimental)',
      'Anomalies Summary (Experimental)': 'Anomalies Summary (Experimental)',
      'Monte Carlo Simulation Summary': 'Monte Carlo Simulation Summary',
      'Monte Carlo Simulation Graph': 'Monte Carlo Simulation Graph',
      'itterations for': 'itterations for',
      intervals: 'intervals',
      'generated at': 'generated at',
      'Maximum Price': 'Maximum Price',
      'Minimum Price': 'Minimum Price',
      'Average Price': 'Average Price',
      'As Percentage Change': 'As Percentage Change',
      Interval: 'Interval',
      'Of Simulations': 'Of Simulations',
      to: 'to',
      'Less Than': 'Less Than',
      'More Than': 'More Than',
      cookies_consent:
        'This site uses cookies to enhance your user experience. Continued use of this site or clicking "I Agree" indicates your consent.',
      more_information: 'More Information',
      i_agree: 'I Agree',
      cookie_policy_title: 'Cookie Policy',
      what_are_cookies: 'What are cookies?',
      what_is_cookie_text:
        "An cookie is a small piece of data stored on the user's device by the web browser while browsing a website.",
      what_is_cookie_text_additional:
        "Cookies store information about site settings as (language, theme etc.) and the fact that you have already visited certain pages.  Thus, you don't have to set them when you visit the same page again. Some of the cookies are needed for proper website functioning while and other types of cookies are used for gathering statistical data",
      cookie_types_text: 'Webpage fondovi.mk uses following type of cookies:',
      cookies_user_preferences: 'Cookies for storing user preferences on website visit (ex. language, theme etc.)',
      cookies_statistics_and_analytics: 'Cookies used for statistics and analytics',
      cookies_third_party: 'Third party cookies',
      close: 'Close',
      date_start_date: 'Date / Start Date',
      end_date: 'End Date',
      anomaly_detector_algo: 'Detecion algorithm',
      auto_regression_linear: 'Autoregression (linear)',
      auto_regression_non_linear: 'Autoregression (non linear)',
      volatility_shift: 'Volatility Shift',
      trend_shift: 'Change In Trend',
      trend_shift_1: 'Change In Trend 2',
      Collapse: 'Collapse',
      Expand: 'Expand',
      anomaly_description_text:
        "The price anomalies are detected using experimental & heuristic methods as part of scientific research. They shouldn't be treated as ground truth",
      footer_disclaimer_1:
        'Disclaimer: Price and net value data which is displayed on this site has been collected from the public website of the',
      mse: 'Macedonian Stock Exchange',
      footer_disclaimer_2:
        'fondovi.mk does not provide any guarantees about the accuracy of the collected and displayed data. Any use of it is at your own risk.',
      disclaimer_label: 'Disclaimer And Usage Notice',
      disclaimers_content_title: 'Disclaimers for fondovi.mk',
      disclaimers_content_p1:
        'All investments, including mutual funds, are speculative in nature and are involving substantial risk of loss.',
      disclaimers_content_p2:
        'You should invest carefully. Potential investors should get personal advice from professional investment advisor and make independent investigations before acting on any information.',
      disclaimers_content_p3:
        'Past performance is not necessarily indicative of future results. All investments carry risk and all investment decisions of an individual remain the responsibility of that individual. There is no guarantee that systems, indicators, or signals will result in profits or that they will not result in losses.',
      disclaimers_content_p4: 'fondovi.mk is a hobby / ”pet” project and is not formally audited!',
      disclaimers_content_p5:
        'The author of the software is NOT RESPONSIBLE for any damages which might occur while using this website.',
      disclaimers_content_p6:
        'All the information on this website - fondovi.mk - is published in good faith and for general information purpose only. fondovi.mk does not make any warranties about the completeness, reliability and accuracy of this information. Any action you take upon the information you find on this website (fondovi.mk), is strictly at your own risk. fondovi.mk will not be liable for any losses and/or damages in connection with the use of our website.',
      disclaimers_content_p7:
        "From our website, you can visit other websites by following hyperlinks to such external sites. While we strive to provide only quality links to useful and ethical websites, we have no control over the content and nature of these sites. These links to other websites do not imply a recommendation for all the content found on these sites. Site owners and content may change without notice and may occur before we have the opportunity to remove a link which may have gone 'bad'.",
      disclaimers_content_p8:
        'Please be also aware that when you leave our website, other sites may have different privacy policies and terms which are beyond our control. Please be sure to check the Privacy Policies of these sites as well as their "Terms of Service" before engaging in any business or uploading any information.',
      consent: 'Consent',
      disclaimers_consent_p1: 'By using our website, you hereby consent to our disclaimer and agree to its terms.',
      update: 'Update',
      disclaimer_update_p1:
        'Should we update, amend or make any changes to this document, those changes will be prominently posted here.',
      general_indicators: 'General Indicators',
      volatility_pca_title: 'Multivariate Volatility Analisys',
      volatility_pca_explanation: '* Using moving averages and principal component analysis (PCA)',
      volatility_pca_explanation_2:
        "The anomalies of the time series are detected using experimental & heuristic methods as part of scientific research. They shouldn't be treated as ground truth",
      volatility_pca_chart_title: 'Volatility PCA component',
      explained_variance: 'Percent Of Explained Variance',
      difference: 'Difference',
      fund_selector_info: 'You can toggle between analysis for all funds or detailed analysis for specific funds.',
      blog: 'Blog',
      fund_anomaly_data_not_found: 'Anomaly analysis cannot be obtained for selected fund.',
    },
  },
  mk: {
    translation: {
      Date: 'Датум',
      Investment: 'Инвестиција',
      'Investment Calculator': 'Инвестициски Калкулатор',
      oif_analysis: 'Анализа На Отворени Инвестициски Фондови',
      oif_analysis_short: 'Анализа На ОИФ',
      'All Funds': 'Сите Фондови',
      'Investment Configuration': 'Поставки на инвестицијата',
      'Initial Investment': 'Иницијална инвестиција',
      'Periodic Contribution': 'Постепена уплата',
      'Initial value you want to invest': 'Иницијална вредност на инвестицијата',
      'How much do you want to invest each period': 'Вредност на постепените уплати',
      'Contribution Interval': 'Интервал',
      'Interval for periodic contributions': 'Интервал на постепените уплати',
      'Expected Return': 'Очекуван принос',
      'Expected return in percent': 'Очекуван принос во проценти',
      'Term (In Years)': 'Период (во години)',
      Compounding: 'Вкаматување',
      'How long you want to invest': 'Времетраење на инвестицијата',
      'Compounding type': 'Начин на вкаматување',
      Weekly: 'Неделно',
      'Bi-weekly': 'Двонеделно',
      Monthly: 'Месечно',
      Quarterly: 'Квартално',
      'Semi-annually': 'Полу годишно',
      Yearly: 'Годишно',
      'Investment Flow': 'Инвестициски тек',
      Period: 'Период',
      Value: 'Износ',
      'Investment Flow Details': 'Детали за инвестицискиот тек',
      'Annual investment': 'Годишна инвестиција',
      Year: 'год.',
      year_long: 'Година',
      Years: 'год.',
      start: 'почеток',
      'Cumulative Investment': 'Кумулативна инвестиција',
      'Annual Interest': 'Годишен принос',
      'Total Interest Earned': 'Вкупна камата',
      'Initial Investment Value': 'Вредност на иницијалната инвестиција',
      'Contribution Value': 'Вредност на постепена уплата',
      'Total Investment Value': 'Вкупна вредност на инвестицијата',
      difference_in_percent: 'Разлика (во %)',
      'Portfolio Summary': 'Портфолио резиме',
      'Grand Total': 'Крајна сума',
      Overview: 'Преглед',
      'Risk Analysis': 'Анализа на ризик',
      'Price Anomalies': 'Ценовни аномалии',
      'Net Value': 'Нето вредност',
      'Net Value (All Funds)': 'Нето вредност (сите фондови)',
      MKD: 'ден.',
      'Risk/Return Chart': 'Графикон (ризик/принос)',
      show_cluster_groups: 'Групирај во кластери (експериментално)',
      'Volatility (STD % From Weekly Returns)': 'Волатилност (СТД % од седмични приноси)',
      'Average Annual Return (From Weekly Returns)': 'Просечен годишен принос (од седмични приноси)',
      Name: 'Име',
      Daily: 'Дневно',
      'Unit Price': 'Цена на удел',
      Months: 'мес.',
      months_long: 'Месеци',
      'Year To Date': 'ОПГ',
      'Date Updated': 'Датум на вреднување',
      'Funds Overview': 'Преглед на фондови',
      'General Info': 'Општи информации',
      'Price Analysis': 'Ценовна анализа',
      Simulations: 'Симулации',
      'Since Inception': 'Од основање',
      Summary: 'Преглед',
      'Risk Class': 'Ризична класа',
      'Average Annual Return': 'Просечен годишен принос',
      'From Daily Returns': 'од дневни приноси',
      'From Weekly Returns': 'од седмични приноси',
      Volatility: 'Волатилност',
      'Sharpe Ratio': 'Шарпов коефициент',
      'Using Risk Free Rate': 'Берзична каматна стапка = 0',
      'Time Series Stability': 'Стабилност на временската серија',
      'Tail Ratio': 'Однос на опашката',
      'Maximum Drawdown': 'Максимално опаѓање',
      'Annual Total Return': 'Годишен принос',
      'Total Return': 'Принос',
      'Hypothetical Growth Of': 'Хипотетички раст на',
      Classification: 'Класификација',
      'Less than': 'Помал од',
      Bad: 'Лошо',
      Acceptable: 'Прифатливо',
      Great: 'Добро',
      Excellent: 'Одлично',
      'More than': 'Поголем од',
      Last: 'Последни',
      Since: 'Од',
      'Anomalies Graph (Experimental)': 'График на аномалии (експериментално)',
      'Anomalies Summary (Experimental)': 'Преглед на аномалии (експериментално)',
      'Monte Carlo Simulation Summary': 'Резиме на монте карло симулација',
      'Monte Carlo Simulation Graph': 'График на монте карло симулација',
      'itterations for': 'итерации за',
      intervals: 'интервали',
      'generated at': 'генерирана на',
      'Maximum Price': 'Максимална цена',
      'Minimum Price': 'Минимална цена',
      'Average Price': 'Просечна цена',
      'As Percentage Change': 'разлика во проценти',
      Interval: 'Интервал',
      'Of Simulations': 'од симулации',
      to: 'до',
      'Less Than': 'Помалку од',
      'More Than': 'Повеќе од',
      cookies_consent:
        'Оваа страна користи колачиња (cookies). Со продолжување на користење на страната или кликнување на “Се согласувам” се согласувате со користењето на колачињата.',
      more_information: 'Повеќе информации',
      i_agree: 'Се согласувам',
      cookie_policy_title: 'Политика за колачиња',
      what_are_cookies: 'Што се колачиња?',
      what_is_cookie_text:
        'Колаче е мала текстуална датотека која веб-локацијата ја складира на вашиот уред кога ја посетувате локацијата.',
      what_is_cookie_text_additional:
        'Колачињата чуваат информации за вашите поставки во веб прелистувачот, како на пример: јазик, тема и самиот факт дека веќе сте посетиле одредени страници. На тој начин при повторна посета на истата страница не мора повторно да ги поставувате. Некои од колачињата се неопходни за функционирање на страниците, а некои служат и за собирање статистички податоци.',
      cookie_types_text: 'Веб страницата на fondovi.mk ги користи следните типови колачиња:',
      cookies_user_preferences:
        'Колачиња за зачувување на ваши поставки при посета на страницата (како јазик, тема и тн.)',
      cookies_statistics_and_analytics: 'Колачиња кои служат за статистички и аналитички анализи',
      cookies_third_party: 'Колачиња од трета страна',
      close: 'Затвори',
      date_start_date: 'Датум / почетен датум',
      end_date: 'Краен датум',
      anomaly_detector_algo: 'Алгоритам за детекција',
      auto_regression_linear: 'Авторегресија (линеарна)',
      auto_regression_non_linear: 'Авторегресија (нелинеарна)',
      volatility_shift: 'Промена на волатилност',
      trend_shift: 'Промена на тренд',
      trend_shift_1: 'Промена на тренд 2',
      Collapse: 'Стесни',
      Expand: 'Прошири',
      anomaly_description_text:
        'Ценовните аномалии се детектирани со користење на експериментални и хевристички методи како дел од научно истражување. Вредностите не треба да се третираат како апсолутна вистина',
      footer_disclaimer_1:
        'Напомена: Податоците (цена и нето вредност) кои што се прикажани на оваа страна се прибрани од јавната страна на',
      mse: 'Македонска берза',
      footer_disclaimer_2:
        'fondovi.mk не гарантира за точноста на прибраните и прикажаните податоци. Секое користење на истите претставува ваш ризик.',
      disclaimer_label: 'Општи одредби и услови на употреба',
      disclaimers_content_title: 'Услови на употреба',
      disclaimers_content_p1:
        'Сите инвестиции, вклучувајќи ги и инвестициските фондови имаат спекулативна основа и истите инкорпорираат суштинско ниво на ризик.',
      disclaimers_content_p2:
        'Потенцијалните инвеститори треба да инвестираат претпазливо, и да побараат совет од професионален инвестициски советник или портофлио менаџер. Инвеститорите треба да направат свое истражување пред да донесат одлуки врз база на било каква информација (вклучувајќи ги информациите презентирани на fondovi.mk).',
      disclaimers_content_p3:
        'Информациите за фондовите од изминатите периоди не се показател за идните перформанси и резултати на фондот. Сите инвестиции инволвираат ризик и за сите одлуки во врска со инвестициите е одговорен лично самиот инвеститор. Не постои никаква гаранција дека дадени системи, индикатори, сигнали или било каков тип на информации поврзани со фондовите се показател дека инвестирањето во фондот ќе резултира со добивка или дека нема да резултира со загуба.',
      disclaimers_content_p4: 'fondovi.mk е хоби проект и не е формално ревидиран и проверен!',
      disclaimers_content_p5:
        'Авторот на овој софтвер/страна (fondovi.mk) НЕ Е ОДГОВОРЕН за било какви загуби и штети што можат да настанат со користењето на оваа страна.',
      disclaimers_content_p6:
        'Сите информации објавени на оваа страна (fondovi.mk) се од информативен карактер. fondovi.mk не гарантира за нивна комплетност, достапност, точност, навременост и автентичност. Корисникот на оваа веб страна (fondovi.mk) се согласува дека го презема ризикот доколку користи информации прикажани од оваа страна (fondovi.mk).',
      disclaimers_content_p7:
        'Преку сајтот на fondovi.mk, корисникот може да посети други веб страни преку линковите наведени на страната. Иако креаторот на fondovi.mk се грижи да објавува линкови со корисна содржина до корисни и етички веб страни, креаторот на fondovi.mk нема контрола врз содржината на екстерните веб страни. Линковите до надворешни страни не имплицираат препорака за целата содржина на екстерните страни. Сопствениците на екстерните страни можат да ја променат содржината во било кое време.',
      disclaimers_content_p8:
        'Кога корисникот ќе ја напушти оваа страна треба да биде свесен дека останатите страни имаат други одредби, услови на употреба и политики.',
      consent: 'Согласност',
      disclaimers_consent_p1:
        'Со користење на оваа страна (fondovi.mk), корисникот се согласува со општите одредби и условите на користење.',
      update: 'Измени',
      disclaimer_update_p1:
        'Доколку има измени во одредбите и условите на употреба промените ќе бидат прикажани во оваа секција.',
      general_indicators: 'Генерални индикатори',
      volatility_pca_title: 'Мултиваријациона анализа на волатилност',
      volatility_pca_explanation:
        '* Анализа на подвижни средини (moving averages) со користење на aнализа на главни компоненти (PCA)',
      volatility_pca_explanation_2:
        'Аномалиите на временската серија се детектирани со користење на експериментални и хевристички методи како дел од научно истражување. Вредностите не треба да се третираат како апсолутна вистина',
      volatility_pca_chart_title: 'Волатилност (главна компонента)',
      explained_variance: 'Процент на објаснета варијанса',
      '1m': '1М',
      '3m': '3М',
      '6m': '6M',
      YTD: 'ОПГ',
      '1y': '1Г',
      All: 'Сите',
      difference: 'Разлика',
      fund_selector_info:
        'Погледнете ги анализите што се однесуваат на сите фондови или изберете поединечен фонд за преглед на детална анализа.',
      blog: 'Блог',
      fund_anomaly_data_not_found: 'Анализата на аномалии не е достапна селектираниот фонд.',
    },
  },
};

function getLanguage() {
  if (
    navigator.languages !== undefined &&
    (navigator.languages.includes('mk') || navigator.languages.includes('mk-MK'))
  ) {
    return 'mk';
  }

  return 'en';
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'mk',

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })
  .then(() => {
    // fetch('http://ip-api.com/json')
    //   .then((results) => results.json())
    //   .then((jsonData) => {
    //     if (jsonData && jsonData.countryCode === 'MK') {
    //       i18n.changeLanguage('mk');
    //     }
    //   })
    //   .catch(() => {});

    const translationMap = {};
    fetch(`${BACKEND_API}fundsMeta/names`)
      .then((results) => results.json())
      .then((jsonData) => {
        // @todo workaround.. i should fix this
        // eslint-disable-next-line no-underscore-dangle
        window._funds = jsonData;
        jsonData.forEach((fundData) => {
          translationMap[fundData.name] = fundData.name_mk;
        });
        i18n.addResourceBundle('mk', 'fundNames', translationMap);
        // i18n.changeLanguage(getLanguage());
        i18n.changeLanguage('mk');
      })
      .catch(() => {});
  });

export default i18n;
