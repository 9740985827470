import React from 'react';
import { withTranslation } from 'react-i18next';
import styles from './styles.module.scss';

class PortfolioSummary extends React.Component {
  componentDidUpdate(prevProps) {
    const { cumulativeInvestmentTableData } = this.props;
    // TODO: Rewrite this with ImmutableJS
    if (
      prevProps.cumulativeInvestmentTableData &&
      JSON.stringify(cumulativeInvestmentTableData.investmentPeriodData) !==
        JSON.stringify(prevProps.cumulativeInvestmentTableData.investmentPeriodData)
    ) {
      this.forceUpdate();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  formatMoney(number) {
    // return number.toLocaleString('en-US', { style: 'currency', currency: 'MKD' });
    // return number.toLocaleString('mk-MK');
    return number.toLocaleString('en-US');
  }

  // cellRenderer(rowIndex, column) {
  // const { cumulativeInvestmentTableData } = this.props;
  // if (!cumulativeInvestmentTableData.investmentPeriodData[rowIndex]) {
  //   return (
  //     <Cell>
  //       NaN
  //     </Cell>
  //   );
  // }
  // return (
  //   <Cell>
  //     {this.formatMoney(cumulativeInvestmentTableData.investmentPeriodData[rowIndex][column])}
  //   </Cell>
  // );
  // }

  render() {
    const { cumulativeInvestmentTableData } = this.props;
    const { t } = this.props;
    if (!cumulativeInvestmentTableData) {
      return <h4 style={{ textAlign: 'center', paddingBotoom: '15%' }}>Loading.....</h4>;
    }

    return (
      <>
        <table className={`bp3-html-table bp3-html-table-striped bp3-html-table-bordered ${styles.PortfolioSummary}`}>
          <thead>
            <tr>
              <th>{t('Investment')}</th>
              <th>{t('Cumulative Investment')}</th>
              <th>{t('Total Investment Value')}</th>
              <th>{t('difference_in_percent')}</th>
            </tr>
          </thead>
          <tbody>
            {cumulativeInvestmentTableData.map((value, key) => {
              let percentageDifference = (
                ((value.total_value_investment - value.amount_invested) / value.amount_invested) *
                100
              ).toFixed(2);
              percentageDifference = isNaN(percentageDifference) === true ? 0 : percentageDifference;

              if (key === cumulativeInvestmentTableData.length - 1) {
                return (
                  <tr key={key}>
                    <td>
                      <b>{t('Grand Total')}</b>
                    </td>
                    <td>
                      <b>{value.amount_invested}</b>
                    </td>
                    <td>
                      <b>{value.total_value_investment}</b>
                    </td>
                    <td>
                      <b>{percentageDifference} %</b>
                    </td>
                  </tr>
                );
              }

              return (
                <tr key={key}>
                  <td>{`${t('Investment')} ${value.id}`}</td>
                  <td>{value.amount_invested}</td>
                  <td>{value.total_value_investment}</td>
                  <td>{percentageDifference} %</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  }
}

export default withTranslation()(React.memo(PortfolioSummary));
