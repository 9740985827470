import React from 'react';
import { withTranslation } from 'react-i18next';
import styles from './styles.module.scss';

class InvestmentFlowDetails extends React.Component {
  componentDidUpdate(prevProps) {
    const { investmentDetails } = this.props;
    // TODO: Rewrite this with ImmutableJS
    if (
      prevProps.investmentDetails &&
      JSON.stringify(investmentDetails.investmentPeriodData) !==
        JSON.stringify(prevProps.investmentDetails.investmentPeriodData)
    ) {
      this.forceUpdate();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  formatMoney(number) {
    // return number.toLocaleString('en-US', { style: 'currency', currency: 'MKD' });
    // return number.toLocaleString('mk-MK');
    return number.toLocaleString('en-US');
  }

  render() {
    const { investmentDetails } = this.props;
    const { t } = this.props;
    if (!investmentDetails) {
      return <h4 style={{ textAlign: 'center', paddingBotoom: '15%' }}>Loading.....</h4>;
    }

    return (
      <table
        className={`bp3-html-table bp3-html-table-striped bp3-html-table-bordered ${styles.InvestmentFlowDetails}`}
      >
        <thead>
          <tr>
            <th>{t('year_long')}</th>
            <th>{t('Annual investment')}</th>
            <th>{t('Cumulative Investment')}</th>
            <th>{t('Annual Interest')}</th>
            <th>{t('Total Interest Earned')}</th>
            <th>{t('Initial Investment Value')}</th>
            <th>{t('Contribution Value')}</th>
            <th>{t('Total Investment Value')}</th>
          </tr>
        </thead>
        <tbody>
          {investmentDetails.investmentPeriodData.map((value, key) => {
            return (
              <tr key={key}>
                <td>{key === 0 ? t('start') : key}</td>
                <td>{this.formatMoney(value.annual_investment)}</td>
                <td>{this.formatMoney(value.cumulative_investment)}</td>
                <td>{this.formatMoney(value.annual_interest)}</td>
                <td>{this.formatMoney(value.total_interest_earned)}</td>
                <td>{this.formatMoney(value.value_initial_investment)}</td>
                <td>{this.formatMoney(value.value_addition)}</td>
                <td>{this.formatMoney(value.total_value_investments)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}

export default withTranslation()(React.memo(InvestmentFlowDetails));
