/* eslint-disable class-methods-use-this */
import React, { useEffect, useState, useContext } from 'react';

import { Tag, Colors } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../../../../utils/Date';

import { ThemeContext } from '../../../../../context/ThemeProvider';
import './styles.scss';

function AnomalySummary({ data }) {
  const { t } = useTranslation();
  const [anomalyPeriods, setAnomalyPeriods] = useState([]);
  const { theme } = useContext(ThemeContext);
  const [redColor, setRedColor] = useState(theme === 'dark' ? Colors.RED5 : Colors.RED1);

  useEffect(() => {
    setRedColor(theme === 'dark' ? Colors.RED5 : Colors.RED1);
    if (data && data.summary) {
      const items = Object.entries(data.summary).map(([key, value]) => {
        const anomalyTypes = [];
        value.anomaly_types.forEach((anomalyType) => {
          anomalyTypes.push(
            <Tag
              key={`${value.from ? value.from : value.data}_${anomalyType}`}
              style={{ marginRight: '5px', marginBottom: '5px' }}
            >
              {t(anomalyType)}
            </Tag>
          );
        });
        if (value.range) {
          return (
            <tr key={value.from}>
              <td key={`${value.from}_from`}>{formatDate(value.from)}</td>
              <td key={`${value.from}_to`}>{formatDate(value.to)}</td>
              <td key={`${value.from}_type`} style={{ textAlign: 'right' }}>
                {anomalyTypes}
              </td>
            </tr>
          );
        }
        return (
          <tr key={value.date}>
            <td key={`${value.date}_from`}>{formatDate(value.date)}</td>
            <td key={`${value.date}_to`}>/</td>
            <td key={`${value.date}_type`} style={{ textAlign: 'right' }}>
              {anomalyTypes}
            </td>
          </tr>
        );
      });
      setAnomalyPeriods(items);
    }
  }, [data, theme, redColor, t]);

  if (!data) {
    return <></>;
  }

  return (
    <table className={`bp3-html-table annual-return-table table-${theme}`}>
      <thead>
        <tr>
          <th style={{ width: '25%', fontWeight: 500 }}>{t('date_start_date')}</th>
          <th style={{ width: '25%', fontWeight: 500 }}>{t('end_date')}</th>
          <th style={{ width: '50%', textAlign: 'right', fontWeight: 500 }}>{t('anomaly_detector_algo')}</th>
        </tr>
      </thead>
      <tbody>{anomalyPeriods}</tbody>
    </table>
  );
}

export default AnomalySummary;
