// eslint-disable-next-line import/prefer-default-export
import React from 'react';

const getTooltipStyle = (theme, point, numberOfDataPoints = null) => {
  let style = {
    background: 'white',
    padding: '9px 12px',
    border: '1px solid #ccc',
  };

  if (theme === 'dark') {
    style = {
      background: '#30404d',
      padding: '9px 12px',
      border: '1px solid #ccc',
    };
  }

  if (!numberOfDataPoints) {
    return style;
  }

  if (point.index + 1 <= (numberOfDataPoints / 100) * 10) {
    style.top = '-100px';
    style.position = 'absolute';
    style.left = '0px';
    style.minWidth = '200px';
    style.width = '100%';
  }

  let upperPercentLimit = 90;
  if (window.innerWidth < 600) {
    upperPercentLimit = 65;
  }

  if (point.index + 1 >= (numberOfDataPoints / 100) * upperPercentLimit) {
    style.top = '-100px';
    style.position = 'absolute';
    style.right = '0px';
    style.minWidth = '200px';
  }

  return style;
};

// eslint-disable-next-line import/prefer-default-export
export const tooltip = (
  point,
  theme,
  xLabel = 'Year',
  yLabel = 'Value',
  numberOfDataPoints = null,
  additionalValues = []
) => {
  const additionalValuesArray = additionalValues.map((item) => (
    <div key={item.label}>
      {item.label}: {item.value}
    </div>
  ));
  return (
    <div style={getTooltipStyle(theme, point, numberOfDataPoints)}>
      <div
        key={point.id}
        style={{
          color: point.serieColor,
          padding: '3px 0',
        }}
      >
        <strong>{point.serieId}</strong>
      </div>
      <div>
        {xLabel}: {point.data.xFormatted}
      </div>
      <div>
        {yLabel}: {point.data.yFormatted}
      </div>
      {additionalValuesArray}
    </div>
  );
};
