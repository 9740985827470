/* eslint-disable class-methods-use-this */
import { ResponsiveScatterPlot } from '@nivo/scatterplot';
import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch } from '@blueprintjs/core';
import { BACKEND_API } from '../../../../global/environment';
import { ThemeContext } from '../../../../context/ThemeProvider';
import Loader from '../../../../components/loader';
import styles from './styles.module.scss';

function RiskReturnChart() {
  const { theme } = useContext(ThemeContext);
  const { t } = useTranslation();
  const { t: tFunds } = useTranslation('fundNames');
  const [fundsData, setFundsData] = useState([]);
  const [fundsDataOriginal, setFundsDataOriginal] = useState([]);
  const [showClusters, setShowClusters] = useState(false);
  const [customLegendItems, setCustomLegendItems] = useState([]);
  const [width, setWidth] = useState(1920);

  const [nodeId, setNodeId] = useState(null);
  const handleMouseMove = useCallback((node) => setNodeId(node.id), [setNodeId]);
  const handleMouseLeave = useCallback(() => setNodeId(null), [setNodeId]);
  const getNodeSize = useMemo(
    () => (node) => {
      if (nodeId !== null && nodeId === node.id) return 22;
      return 14;
    },
    [nodeId]
  );

  const setWidthWrap = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    setWidth(window.innerWidth);
    window.addEventListener('resize', setWidthWrap);
    return () => {
      window.removeEventListener('resize', setWidthWrap);
    };
  }, []);

  const customColors = ['#F94144', '#43AA8B', '#577590', '#197278', '#F8961E', '#F9C74F', '#90BE6D', '#B80C09'];

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;
    const riskReturnUrl = `${BACKEND_API}fundsMeta/riskReturn`;

    fetch(riskReturnUrl, { signal })
      .then((results) => results.json())
      .then((jsonData) => {
        const results = [];
        jsonData.forEach((fund, key) => {
          results.push({
            id: tFunds(fund.name),
            color: customColors[fund.cluster],
            cluster: fund.cluster,
            data: [
              {
                x: fund.risk,
                y: fund.return,
                color: customColors[fund.cluster],
              },
            ],
          });
        });
        setFundsData(results);
        setFundsDataOriginal(JSON.parse(JSON.stringify(results)));
      })
      .catch(() => {});
    return function cleanup() {
      abortController.abort();
    };
  }, [t]);

  useEffect(() => {
    setCustomLegendItems(
      fundsDataOriginal.map((value) => (
        <div key={value.id} className={styles.customLegendItem}>
          <div
            className={styles.dot}
            style={{ backgroundColor: showClusters === true ? customColors[value.cluster] : value.color }}
          />
          <span>{value.id}</span>
        </div>
      ))
    );
  }, [showClusters, fundsDataOriginal]);

  const getTooltipStyle = () => {
    let style = {
      background: 'white',
      padding: '9px 12px',
      border: '1px solid #ccc',
    };

    if (theme === 'dark') {
      style = {
        background: '#30404d',
        padding: '9px 12px',
        border: '1px solid #ccc',
      };
    }

    return style;
  };

  if (!fundsData[0]) {
    return (
      <div style={{ height: '500px' }}>
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div style={{ height: '500px' }}>
        <Switch
          large
          checked={showClusters}
          label={t('show_cluster_groups')}
          style={{ float: 'right', zIndex: 2 }}
          onChange={() => setShowClusters(!showClusters)}
        />
        <ResponsiveScatterPlot
          data={fundsData}
          margin={{ top: 15, right: width < 1366 ? 10 : 200, bottom: 70, left: 50 }}
          xScale={{ type: 'linear', min: 'auto', max: 'auto' }}
          yScale={{ type: 'linear', min: 'auto', max: 'auto' }}
          blendMode={theme === 'dark' ? 'normal' : 'darken'}
          axisTop={null}
          axisRight={null}
          nodeSize={getNodeSize}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
          theme={{ fontSize: '14', axis: { legend: { text: '15' } } }}
          // colors={showClusters === true ? (d) => d.color : { scheme: 'category10' }}
          colors={
            showClusters === true
              ? (d) => {
                  if (d.id) {
                    const fundData = fundsData.find(
                      (fundDataItem) => fundDataItem.id === d.id.substring(0, d.id.length - 2)
                    );
                    return fundData.color;
                  }
                  const fundData = fundsData.find((fundDataItem) => fundDataItem.id === d.serieId);
                  return fundData.color;
                }
              : { scheme: theme === 'dark' ? 'red_yellow_green' : 'category10' }
          }
          tooltip={({ node }) => {
            const tooltipStyle = getTooltipStyle();
            if (node.x < 100) {
              tooltipStyle.top = '-100px';
              tooltipStyle.position = 'absolute';
              tooltipStyle.left = '0px';
              tooltipStyle.minWidth = '250px';
            }

            if (width < 900) {
              tooltipStyle.position = 'absolute';
              tooltipStyle.minWidth = '150px';

              if (node.x < 90) {
                tooltipStyle.left = '20px';
              } else {
                tooltipStyle.right = '20px';
              }
            }

            return (
              <div style={tooltipStyle}>
                <div
                  key={node.id}
                  style={{
                    color: node.style.color,
                    padding: '3px 0',
                  }}
                >
                  <strong>{node.data.serieId}</strong>
                </div>
                <div>
                  {t('Volatility')} : {node.data.x}
                </div>
                <div>
                  {t('Average Annual Return')}: {node.data.y}
                </div>
              </div>
            );
          }}
          axisBottom={{
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: t('Volatility (STD % From Weekly Returns)'),
            legendPosition: 'middle',
            legendOffset: 40,
          }}
          axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: t('Average Annual Return (From Weekly Returns)'),
            legendPosition: 'middle',
            legendOffset: -40,
          }}
          legends={[
            {
              anchor: 'bottom-right',
              direction: 'column',
              justify: false,
              translateX: 220,
              translateY: 0,
              itemWidth: 200,
              itemHeight: 20,
              labels: {
                usePointStyle: true,
              },
              itemsSpacing: 5,
              itemDirection: 'left-to-right',
              symbolSize: 12,
              symbolShape: 'circle',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemOpacity: 0.5,
                  },
                },
              ],
            },
          ]}
        />
      </div>
      {width < 1366 && <div className={styles.customLegend}> {customLegendItems}</div>}
    </>
  );
}

export default React.memo(RiskReturnChart);
