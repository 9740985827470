/* eslint-disable class-methods-use-this */
import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Colors } from '@blueprintjs/core';
import { ThemeContext } from '../../../../../context/ThemeProvider';
import InlineBar from '../../../../../components/InlineBar';
import './styles.scss';

function AnnualTotalReturn({ returnsData }) {
  const [returnItems, setReturnItems] = useState([]);
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);
  const [redColor, setRedColor] = useState(theme === 'dark' ? Colors.RED5 : Colors.RED1);

  useEffect(() => {
    setRedColor(theme === 'dark' ? Colors.RED5 : Colors.RED1);
    const items = Object.entries(returnsData)
      .reverse()
      .map(([key, value]) => {
        return (
          <tr key={key}>
            <td>{key}</td>
            <td>
              <InlineBar value={value} />
            </td>
            <td style={{ textAlign: 'right', color: value < 0 ? redColor : 'inherit' }}>
              <b>{value}%</b>
            </td>
          </tr>
        );
      });
    setReturnItems(items);
  }, [returnsData, theme, redColor]);

  if (!returnsData) {
    return <></>;
  }

  return (
    <table className={`bp3-html-table annual-return-table table-${theme}`}>
      <thead>
        <tr>
          <th style={{ width: '33.3%', fontWeight: 500 }}>{t('year_long')}</th>
          <th style={{ width: '33.3%' }}> </th>
          <th style={{ width: '33.3%', textAlign: 'right', fontWeight: 500 }}>{t('Total Return')}</th>
        </tr>
      </thead>
      <tbody>{returnItems}</tbody>
    </table>
  );
}

export default AnnualTotalReturn;
