/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
import React, { useEffect, useRef, useLayoutEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { renderToString } from 'react-dom/server';
import { Colors } from '@blueprintjs/core';
import uPlot from 'uplot';
import './uPlot.min.css';
import { ThemeContext } from '../../context/ThemeProvider';
import { tooltip } from '../LineChart/helper';
import { formatDate } from '../../utils/Date';

function CaptureResize(props) {
  const { captureRef } = props;
  const [size, setSize] = useState({});

  useLayoutEffect(() => {
    function updateSize() {
      setSize(captureRef.current.getBoundingClientRect());
    }
    updateSize();
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, [captureRef]);
  return props.children(size);
}

function MyPlot(props) {
  const plotRef = useRef(null);
  const { theme } = useContext(ThemeContext);
  const { i18n } = useTranslation();
  const { t: tFunds } = useTranslation('fundNames');
  const { t } = useTranslation();

  function tooltipsPlugin(opts) {
    function init(u, opts, data) {
      const plot = u.root.querySelector('.u-over');

      const ttc = (u.cursortt = document.createElement('div'));
      ttc.className = 'tooltip';
      ttc.textContent = '(x,y)';
      ttc.style.pointerEvents = 'none';
      ttc.style.position = 'absolute';
      ttc.style.background = 'rgba(0,0,255,0.1)';
      plot.appendChild(ttc);

      u.seriestt = opts.series.map((s, i) => {
        if (i === 0) return undefined;

        const tt = document.createElement('div');
        tt.className = 'tooltip';
        tt.textContent = 'Tooltip!';
        tt.style.pointerEvents = 'none';
        tt.style.position = 'absolute';
        tt.style.background = 'rgba(0,0,0,0.1)';
        tt.style.color = s.color;
        tt.style.display = s.show ? null : 'none';

        tt.style.display = 'none';
        plot.appendChild(tt);
        return tt;
      });

      function hideTips() {
        ttc.style.display = 'none';
        u.seriestt.forEach((tt, i) => {
          if (i === 0) return;

          tt.style.display = 'none';
        });
      }

      function showTips() {
        ttc.style.display = null;
        u.seriestt.forEach((tt, i) => {
          if (i === 0) return;
          if (i === 2) return;

          const s = u.series[i];
          tt.style.display = s.show ? null : 'none';
        });
      }

      plot.addEventListener('mouseleave', () => {
        if (!u.cursor.locked) {
          //	u.setCursor({left: -10, top: -10});
          hideTips();
        }
      });

      plot.addEventListener('mouseenter', () => {
        showTips();
      });

      hideTips();
    }

    function setCursor(u) {
      const { left, top, idx } = u.cursor;
      u.cursortt.style.left = `${left}px`;
      u.cursortt.style.top = `${top}px`;
      u.cursortt.style.display = 'none';
      u.cursortt.textContent = '';

      u.seriestt.forEach((tt, i) => {
        if (i === 0) return;

        const s = u.series[i];

        if (s.show) {
          const xVal = u.data[0][idx];
          const yVal = u.data[i][idx];

          const tooltipDataPoint = {
            id: xVal + yVal,
            index: idx,
            serieColor: Colors.BLUE1,
            serieId: tFunds(t(opts.label)),
            data: [],
          };
          tooltipDataPoint.data.xFormatted = formatDate(xVal * 1000);
          tooltipDataPoint.data.yFormatted = yVal;
          tt.innerHTML = renderToString(
            tooltip(tooltipDataPoint, theme, t('Date'), t(props.yLabel), props.data[0].length)
          );
          tt.style.left = `${left + 10}px`;
          tt.style.top = `${top - 30}px`;
        }
      });
    }

    return {
      hooks: {
        init,
        setCursor,
      },
    };
  }

  const mkShortMonths = ['јан', 'феб', 'мар', 'апр', 'мај', 'јун', 'јул', 'авг', 'сеп', 'окт', 'нов', 'дек'];
  const enShortMonths = ['Jan.', 'Feb.', 'Mar.', 'Apr.', 'May', 'Jun.', 'Jul.', 'Aug.', 'Sep.', 'Oct.', 'Nov.', 'Dec.'];

  const languageData = useRef({
    MMM: i18n.language === 'mk' ? [...mkShortMonths] : [...enShortMonths],
  });

  useEffect(() => {
    if (i18n.language === 'mk') {
      languageData.current.MMM = mkShortMonths;
    } else {
      languageData.current.MMM = enShortMonths;
    }
    window.dispatchEvent(new Event('resize'));
  }, [i18n.language, mkShortMonths, enShortMonths]);

  useEffect(() => {
    // eslint-disable-next-line no-new
    const options = {
      ...props.options,
      plugins:
        typeof props.responsive === 'undefined' || props.responsive !== false ? [tooltipsPlugin(props.options)] : [],
      fmtDate: (tpl) => uPlot.fmtDate(tpl, languageData.current),
    };
    const chart = new uPlot(options, props.data, plotRef.current);
    if (plotRef) plotRef.current = chart;
    return () => {
      if (plotRef) plotRef.current = null;
      chart.destroy();
    };
  }, []);

  const c = useRef(null);

  return (
    <CaptureResize captureRef={c}>
      {(captureSize) => {
        if (typeof props.responsive === 'undefined' || props.responsive !== false) {
          let width = 500;
          let height = 600;
          if (captureSize.width) {
            width = captureSize.width;
          }
          if (width < 400) {
            height = 400;
          }
          if (plotRef.current) {
            plotRef.current.setSize({ width, height });
          }

          if (plotRef.current && props.options && props.options.series) {
            plotRef.current.series[1].stroke = theme === 'dark' ? Colors.BLUE5 : Colors.BLUE1;
            plotRef.current.series[2].stroke = theme === 'dark' ? Colors.RED5 : Colors.RED1;
            plotRef.current.axes[1].grid.stroke = theme === 'light' ? '#EEEEEE' : '#868E94';
            plotRef.current.axes[0].stroke = theme === 'dark' ? Colors.GRAY5 : Colors.DARK_GRAY1;
            plotRef.current.axes[1].stroke = theme === 'dark' ? Colors.GRAY5 : Colors.DARK_GRAY1;
            plotRef.current.redraw();
          }
        }

        return (
          <>
            <div ref={c} style={{ width: '100%', height: '100%' }}>
              <div ref={plotRef} size={captureSize} />
            </div>
          </>
        );
      }}
    </CaptureResize>
  );
}

export default MyPlot;
