/* eslint-disable class-methods-use-this */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-flexbox-grid';
import { Card } from '@blueprintjs/core';
import { BACKEND_API } from '../../../../global/environment';
import TrailingReturns from './TrailingReturns';
import HypotheticalGrowthChart from './HypotheticalGrowthChart';
import AnnualTotalReturn from './AnnualTotalReturn';
import SummaryStatistics from './SummaryStatstics';
import RiskClass from './RiskClass';
import styles from './styles.module.scss';

function GeneralFundInfo({ fundId }) {
  const [fundsData, setFundsData] = useState({ aggregated_returns: {} });
  const { t } = useTranslation();

  useEffect(() => {
    const fetchFundNames = async () => {
      const fundNamesUrl = `${BACKEND_API}funds/${fundId}/keyStatistics`;
      const response = await fetch(fundNamesUrl);
      const jsonData = await response.json();
      setFundsData(jsonData);
    };
    fetchFundNames();
  }, [fundId]);

  return (
    <>
      <TrailingReturns fundId={fundId} />
      <Row className={styles.secondRow}>
        <Col lg={6}>
          <Card style={{ minHeight: '800px' }}>
            <h2>{t('Summary')}</h2>
            <div className={styles.riskClassContainer}>
              <div
                style={{
                  display: 'inline-flex',
                  width: '100%',
                  alignItems: 'flex-end',
                  paddingRight: '11px',
                  minWidth: '220px',
                }}
              >
                <h4 style={{ fontWeight: 500 }}>{t('Risk Class')}</h4>
                <RiskClass riskClass={fundsData.risk_class} />
              </div>
            </div>
            <SummaryStatistics fundData={fundsData} />
            <br />
            <h2>{t('Annual Total Return')}</h2>
            <AnnualTotalReturn returnsData={fundsData.aggregated_returns} />
          </Card>
        </Col>
        <Col lg={6}>
          <Card className={styles.hypotheticalGrowthChartContainer}>
            <h2>
              {t('Hypothetical Growth Of')} 10000 {t('MKD')}
            </h2>
            <HypotheticalGrowthChart fundId={fundId} />
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default GeneralFundInfo;
