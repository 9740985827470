/* eslint-disable react/destructuring-assignment */
import React, { createContext } from 'react';
import useBlogState from '../hooks/useBlogState';

const initialState = {
  categories: [],
  posts: [],
};

export const BlogContext = createContext();

export function BlogProvider(props) {
  const blogState = useBlogState(initialState);
  return <BlogContext.Provider value={blogState}>{props.children}</BlogContext.Provider>;
}
