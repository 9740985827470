/* eslint-disable class-methods-use-this */
import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-flexbox-grid';
import { Card } from '@blueprintjs/core';
import { BACKEND_API } from '../../../../global/environment';
import SummaryStatistics from './SimulationSummaryStatstics';
import LastPriceStatistics from './LastPriceStatistics';
import { ThemeContext } from '../../../../context/ThemeProvider';
import Loader from '../../../../components/loader';
import styles from './styles.module.scss';

function Simulations({ fundId }) {
  const { theme } = useContext(ThemeContext);
  const { t } = useTranslation();
  const [simulationData, setSimulationData] = useState({});
  const [cacheBreaker] = useState(Date.now());

  useEffect(() => {
    const fetchFundNames = async () => {
      const fundNamesUrl = `${BACKEND_API}funds/${fundId}/monteCarloSimulation`;
      const response = await fetch(fundNamesUrl);
      const jsonData = await response.json();
      setSimulationData(jsonData);
    };
    fetchFundNames();
  }, [fundId]);

  const chartField = `chart_url_${theme}`;

  if (!simulationData[chartField]) {
    return <Loader />;
  }

  return (
    <Row>
      <Col lg={6}>
        <Card style={{ minHeight: '800px' }}>
          <h2>{t('Monte Carlo Simulation Summary')}</h2>
          <SummaryStatistics simulationData={simulationData} />
          <LastPriceStatistics simulationData={simulationData.last_price_statistics} />
        </Card>
      </Col>
      <Col lg={6}>
        <Card style={{ height: '650px' }}>
          <h2>{t('Monte Carlo Simulation Graph')}</h2>
          <img
            src={`${BACKEND_API}${simulationData[chartField]}?cb=${cacheBreaker}`}
            alt="Monte Carlo Simulation"
            className={styles.monteCarloChart}
          />
        </Card>
      </Col>
    </Row>
  );
}

export default Simulations;
