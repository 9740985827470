/* eslint-disable import/prefer-default-export */
import { Position, Toaster } from '@blueprintjs/core';
import styles from './styles.module.scss';

/** Singleton toaster instance. Create separate instances for different options. */
export const BottomLeftToaster = Toaster.create({
  className: styles.oifToaster,
  position: Position.BOTTOM_LEFT,
  maxToasts: 1,
});
