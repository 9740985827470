/* eslint-disable class-methods-use-this */
import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Colors } from '@blueprintjs/core';
import { ThemeContext } from '../../../../../context/ThemeProvider';
import InlineBar from '../../../../../components/InlineBar';
import './styles.scss';

function LastPriceStatistics({ simulationData }) {
  const { t } = useTranslation();
  const [returnItems, setReturnItems] = useState([]);
  const { theme } = useContext(ThemeContext);
  const [redColor, setRedColor] = useState(theme === 'dark' ? Colors.RED5 : Colors.RED1);

  useEffect(() => {
    setRedColor(theme === 'dark' ? Colors.RED5 : Colors.RED1);
    const items = Object.entries(simulationData).map(([key, value]) => {
      return (
        <tr key={key}>
          <td>
            {key.replace('to', t('to')).replace('Less Than', t('Less Than')).replace('More Than', t('More Than'))}
          </td>
          <td>
            <InlineBar value={value} />
          </td>
          <td style={{ textAlign: 'right', color: key.includes('-') === true && value > 0 ? redColor : 'inherit' }}>
            <b>{value}%</b>
          </td>
        </tr>
      );
    });
    setReturnItems(items);
  }, [simulationData, theme, redColor, t]);

  if (!simulationData) {
    return <></>;
  }

  return (
    <table className={`bp3-html-table annual-return-table table-${theme}`}>
      <thead>
        <tr>
          <th style={{ width: '33.3%', fontWeight: 500 }}>{t('Interval')}</th>
          <th style={{ width: '33.3%' }}> </th>
          <th style={{ width: '33.3%', textAlign: 'right', fontWeight: 500 }}>% {t('Of Simulations')}</th>
        </tr>
      </thead>
      <tbody>{returnItems}</tbody>
    </table>
  );
}

export default LastPriceStatistics;
