import React, { useContext, useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { Card, Tabs, Tab, Button, Intent } from '@blueprintjs/core';
import { Row, Col, Grid } from 'react-flexbox-grid';
import { useTranslation } from 'react-i18next';
import Header from '../../components/layout/Header';
import GlobalContext from '../../context/global';
import LineChart from '../../components/LineChart';
import CalculatorInputForm from './components/CalculatorInputForm';
import styles from './styles.module.scss';
import InvestmentFlowDetails from './components/InvestmentFlowDetails';
import PortfolioSummary from './components/PortfolioSummary';
import Footer from '../../components/layout/Footer';

const HomePage = () => {
  const globalContext = useContext(GlobalContext);
  const { t } = useTranslation();
  const [fundTabs, setFundTabs] = useState([]);
  const [calculatorInputForms, setCalculatorInputForms] = useState([]);
  const [embeded, setEmbeded] = useState(false);
  const [showCompoundType, setShowCompoundType] = useState(true);
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(`${window.location.pathname}`);
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-underscore-dangle
    const _embeded = new URLSearchParams(location.search).get('embeded');
    if (_embeded && _embeded.toLowerCase() === 'true') {
      setEmbeded(true);
    }

    const paramValues = [
      { name: 'initialInvestment', parseAs: 'int' },
      { name: 'regularAddition', parseAs: 'int' },
      { name: 'interestRate', parseAs: 'float' },
      { name: 'termInYears', parseAs: 'int' },
    ];

    paramValues.forEach((param) => {
      let paramValue = new URLSearchParams(location.search).get(param.name);
      switch (param.parseAs) {
        case 'int':
          paramValue = parseInt(paramValue, 10);
          break;
        case 'float':
          paramValue = parseFloat(paramValue);
          break;
        default:
        // do nothing
      }
      if (paramValue) {
        globalContext.handleChange(0, param.name)(paramValue);
      }
    });

    const backgroundColor = new URLSearchParams(location.search).get('backgroundColor');
    if (backgroundColor) {
      document.body.style.backgroundColor = backgroundColor;
    }

    // eslint-disable-next-line no-underscore-dangle
    const _showCompoundType = new URLSearchParams(location.search).get('showCompoundType');
    if (_showCompoundType && _showCompoundType.toLowerCase() === 'false') {
      setShowCompoundType(false);
    }
  }, [location]);

  useEffect(() => {
    setFundTabs(
      globalContext.investmentCalculator.map((value, index) => (
        <Tab
          id={index}
          key={index}
          title={
            <span>
              {t('Investment')} {index + 1}{' '}
              <Button
                minimal
                icon="remove"
                intent={Intent.DANGER}
                onClick={(e) => {
                  e.stopPropagation();
                  globalContext.setActiveCalculatorInvestmentTab(index > 0 ? index - 1 : 0);
                  globalContext.removeFundFromCalculator(index);
                }}
                disabled={globalContext.investmentCalculator.length < 2}
              />
            </span>
          }
        />
      ))
    );
    const calc = [...globalContext.investmentCalculator];
    setCalculatorInputForms([
      calc.map((value, index) => (
        <CalculatorInputForm
          key={index}
          positionKey={index}
          calculatorInputObj={{ ...value }}
          showCompoundType={showCompoundType}
          className={globalContext.activeCalculatorInvestmentTab === index ? '' : ' hidden'}
        />
      )),
    ]);
  }, [globalContext, t]);

  return (
    <>
      {!embeded && <Header />}
      <Grid fluid className={styles.contentPadding}>
        <Row>
          <Col lg={12} className={styles.investmentsBar}>
            <Card>
              <Tabs
                animate
                id="TabsExample"
                key="horizontal"
                renderActiveTabPanelOnly
                onChange={(e) => {
                  globalContext.setActiveCalculatorInvestmentTab(e);
                }}
                selectedTabId={globalContext.activeCalculatorInvestmentTab}
              >
                {fundTabs}
                <Tabs.Expander />
                <Tabs.Expander />
                <Button
                  icon="add"
                  minimal
                  intent={Intent.PRIMARY}
                  onClick={() => {
                    globalContext.addFundToCalculator();
                    globalContext.setActiveCalculatorInvestmentTab(globalContext.investmentCalculator.length - 1);
                  }}
                  disabled={globalContext.investmentCalculator.length > 9}
                />
              </Tabs>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={3} md={12} className={styles.calculatorInputForm}>
            <Card className={styles.rowElementMinHeight}>
              <h3 style={{ textAlign: 'center', paddingBottom: '1rem' }}>{t('Investment Configuration')}</h3>
              {calculatorInputForms}
            </Card>
          </Col>
          <Col lg={9} md={12} className={styles.calculatorLineChart}>
            <Card className={styles.rowElementMinHeight}>
              <h3 style={{ textAlign: 'center' }}>{t('Investment Flow')}</h3>
              <div className={styles.lineChart}>
                <LineChart data={globalContext.calculatedResultsChart} />
              </div>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={7} md={12} className={styles.investmentFlowDetails}>
            <Card className={styles.investmentFlowDetailsCard}>
              <h3 style={{ textAlign: 'center' }}>{t('Investment Flow Details')}</h3>
              <Tabs
                animate
                id="TabsExample1"
                key="horizontal"
                selectedTabId={globalContext.activeCalculatorInvestmentTab}
                renderActiveTabPanelOnly
              >
                {globalContext.investmentCalculator.map((value, index) => (
                  <Tab
                    id={index}
                    key={index}
                    panel={
                      <InvestmentFlowDetails index={index} investmentDetails={globalContext.calculatedResults[index]} />
                    }
                  />
                ))}
              </Tabs>
            </Card>
          </Col>
          <Col lg={5} md={12} className={styles.investmentFlowDetails}>
            <Card style={{ height: '100%' }} className={styles.portfoliSummaryCard}>
              <h3 style={{ textAlign: 'center' }}>{t('Portfolio Summary')}</h3>
              <PortfolioSummary cumulativeInvestmentTableData={globalContext.cumulativeInvestmentTableData} />
            </Card>
          </Col>
        </Row>
      </Grid>
      {!embeded && <Footer />}
    </>
  );
};

export default HomePage;
