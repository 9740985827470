/* eslint-disable no-underscore-dangle */
import React, { useContext, useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';
import { Row, Col, Grid } from 'react-flexbox-grid';
import { Card } from '@blueprintjs/core';
import GlobalContext from '../../../../context/global';
import RiskReturnChart from '../RiskReturnChart';
import FundsOverview from '../FundsOverview';
import styles from './styles.module.scss';
import AllFundsPriceChart from '../AllFundsPriceChart';
import AllFundsNetValueChart from '../AllFundsNetValueChart';
import HighChartsTaChart from '../HighChartsTaChart';
import GeneralFundInfo from '../GeneralFundInfo';
import Simulations from '../Simulations';
import Anomaly from '../Anomaly';
import GeneralIndicators from '../GeneralIndicators';

const AnalisysGrid = (props) => {
  const globalContext = useContext(GlobalContext);
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('overview');
  const paddingTop = '36px';

  useEffect(() => {
    try {
      let fundName =
        globalContext.fundAnalysisData.selectedFund > 0 ? globalContext.fundAnalysisData.selectedFund : 'general';
      if (window._funds) {
        if (globalContext.fundAnalysisData.selectedFund > 0) {
          fundName = window._funds.filter((f) => f.id === globalContext.fundAnalysisData.selectedFund)[0].name;
        }
      }
      ReactGA.pageview(`${window.location.pathname}/analysis/${fundName}/${props.activeTab}`);
    } catch (err) {}

    if (props.activeTab && props.activeTab !== activeTab) {
      setActiveTab(props.activeTab);
    }
  }, [props]);

  return (
    <>
      <Grid fluid className={styles.analisysContainer}>
        <Row>
          {activeTab === 'overview' && globalContext.fundAnalysisData.selectedFund === 0 && (
            <Col lg={12} className={styles.topCard}>
              <div style={{ paddingTop }}>
                <Card className={styles.fundsOverviewCard}>
                  <h3 style={{ textAlign: 'center', paddingBottom: '3vh' }}>{t('Funds Overview')}</h3>
                  <FundsOverview />
                </Card>
              </div>
            </Col>
          )}
          {activeTab === 'risk' && globalContext.fundAnalysisData.selectedFund === 0 && (
            <Col lg={12} className={styles.topCard}>
              <div style={{ paddingTop }}>
                <Card>
                  <h3 style={{ textAlign: 'center', paddingBottom: '1vh' }}>{t('Risk/Return Chart')}</h3>
                  <RiskReturnChart key={globalContext.fundAnalysisData.selectedFund} />
                </Card>
              </div>
            </Col>
          )}

          {activeTab === 'generalIndicators' && globalContext.fundAnalysisData.selectedFund === 0 && (
            <Col lg={12} className={styles.topCard}>
              <div style={{ paddingTop }}>
                <GeneralIndicators />
              </div>
            </Col>
          )}

          {activeTab === 'price' && globalContext.fundAnalysisData.selectedFund === 0 && (
            <Col lg={12} className={styles.topCard}>
              <div style={{ paddingTop }}>
                <Card style={{ height: '800px' }}>
                  <h3 style={{ textAlign: 'center', paddingBottom: '1vh' }}>Price</h3>
                  <AllFundsPriceChart key={globalContext.fundAnalysisData.selectedFund} />
                </Card>
              </div>
            </Col>
          )}
          {activeTab === 'netValue' && globalContext.fundAnalysisData.selectedFund === 0 && (
            <Col lg={12} className={styles.topCard}>
              <div style={{ paddingTop }}>
                <Card style={{ height: '800px' }}>
                  <h3 style={{ textAlign: 'center', paddingBottom: '1vh' }}>{t('Net Value')}</h3>
                  <AllFundsNetValueChart key={globalContext.fundAnalysisData.selectedFund} />
                </Card>
              </div>
            </Col>
          )}

          {/* Specific funds */}
          {activeTab === 'price' && globalContext.fundAnalysisData.selectedFund > 0 && (
            <Col lg={12} className={styles.topCard}>
              <div style={{ paddingTop }}>
                <Card style={{ minHeight: '800px' }}>
                  <h3 style={{ textAlign: 'center', paddingBottom: '1vh' }}>{t('Price Analysis')}</h3>
                  <HighChartsTaChart
                    fundId={globalContext.fundAnalysisData.selectedFund}
                    key={globalContext.fundAnalysisData.selectedFund}
                  />
                </Card>
              </div>
            </Col>
          )}

          {activeTab === 'general' && globalContext.fundAnalysisData.selectedFund > 0 && (
            <Col lg={12} className={styles.topCard}>
              <div style={{ paddingTop }}>
                <GeneralFundInfo
                  fundId={globalContext.fundAnalysisData.selectedFund}
                  key={globalContext.fundAnalysisData.selectedFund}
                />
              </div>
            </Col>
          )}

          {activeTab === 'simulation' && globalContext.fundAnalysisData.selectedFund > 0 && (
            <Col lg={12} className={styles.topCard}>
              <div style={{ paddingTop }}>
                <Simulations
                  fundId={globalContext.fundAnalysisData.selectedFund}
                  key={globalContext.fundAnalysisData.selectedFund}
                />
              </div>
            </Col>
          )}

          {activeTab === 'anomaly' && globalContext.fundAnalysisData.selectedFund > 0 && (
            <Col lg={12} className={styles.topCard}>
              <div style={{ paddingTop }}>
                <Anomaly
                  fundId={globalContext.fundAnalysisData.selectedFund}
                  key={globalContext.fundAnalysisData.selectedFund}
                />
              </div>
            </Col>
          )}
        </Row>
      </Grid>
    </>
  );
};

export default AnalisysGrid;
