/* eslint-disable class-methods-use-this */
import React, { useContext } from 'react';
import { ThemeContext } from '../../../../../context/ThemeProvider';
import './styles.scss';

function RiskClass({ riskClass }) {
  const { theme } = useContext(ThemeContext);

  if (!riskClass) {
    return <></>;
  }

  return (
    <div className="risk-class-box">
      <div className={`risk-class risk-class-${riskClass} ${theme === 'dark' ? 'dark' : ''}`}>
        <span />
        <span />
        <span />
        <span />
        <span />
        <span />
        <span />
      </div>
    </div>
  );
}

export default RiskClass;
