/* eslint-disable no-param-reassign */
import React, { useContext, useState, useEffect } from 'react';
import { FormGroup, NumericInput, HTMLSelect } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import styles from './styles.module.scss';
import GlobalContext from '../../../../context/global';

export default ({ positionKey, calculatorInputObj, className, showCompoundType = true }) => {
  const globalContext = useContext(GlobalContext);
  const { t } = useTranslation();

  if (!window.debouncedCalculatorInput) {
    window.debouncedCalculatorInput = _.debounce((positionKey, fieldKey, value) => {
      // if (calculatorInputObj[fieldKey] !== value) {
      globalContext.handleChange(positionKey, fieldKey)(value);
      // }
    }, 500);
  }

  const [initialInvestment, setInitialInvestment] = useState(calculatorInputObj.initialInvestment);
  const [regularAddition, setRegularAddition] = useState(calculatorInputObj.regularAddition);
  const [additionPeriod, setAdditionPeriod] = useState(calculatorInputObj.additionPeriod);
  const [interestRate, setInterestRate] = useState(calculatorInputObj.interestRate);
  const [termInYears, setTermInYears] = useState(calculatorInputObj.termInYears);
  const [interestCompoundedType, setInterestCompoundedType] = useState(calculatorInputObj.interestCompoundedType);

  useEffect(() => {
    setInitialInvestment(calculatorInputObj.initialInvestment);
    setRegularAddition(calculatorInputObj.regularAddition);
    setAdditionPeriod(calculatorInputObj.additionPeriod);
    setInterestRate(calculatorInputObj.interestRate);
    setTermInYears(calculatorInputObj.termInYears);
    setInterestCompoundedType(calculatorInputObj.interestCompoundedType);
  }, [calculatorInputObj]);

  const isNumber = (number) => {
    return typeof number === 'number' && !Number.isNaN(number) && Infinity > number && -Infinity < number;
  };

  const handleChange = (positionKey, fieldKey) => (value, strValue) => {
    if (fieldKey === 'initialInvestment') {
      if (!isNumber(value)) {
        return;
      }
      value = Math.abs(value);
      setInitialInvestment(value);
    } else if (fieldKey === 'regularAddition') {
      if (!isNumber(value)) {
        return;
      }
      value = Math.abs(value);
      setRegularAddition(value);
    } else if (fieldKey === 'additionPeriod') {
      setAdditionPeriod(value);
    } else if (fieldKey === 'interestRate') {
      if (!isNumber(value)) {
        return;
      }
      strValue = strValue.replace('-', '');
      if (strValue < 0.00001) {
        strValue = 0.00001;
      }
      setInterestRate(strValue);
      value = strValue;
    } else if (fieldKey === 'termInYears') {
      if (!isNumber(value)) {
        return;
      }
      value = Math.abs(value);
      value = value > 60 ? 60 : value;
      setTermInYears(value);
    } else if (fieldKey === 'interestCompoundedType') {
      setInterestCompoundedType(value);
    }

    window.debouncedCalculatorInput(positionKey, fieldKey, value);
  };

  return (
    <>
      <div className={`${styles.calculatorInputForm}${className}`}>
        <FormGroup
          inline
          helperText={t('Initial value you want to invest')}
          label={t('Initial Investment')}
          labelFor={`initial-value-${positionKey}`}
        >
          <NumericInput
            id={`initial-value-${positionKey}`}
            min="0"
            placeholder={t('Initial Investment')}
            value={initialInvestment}
            onValueChange={handleChange(positionKey, 'initialInvestment')}
          />
        </FormGroup>
        <FormGroup
          inline
          helperText={t('How much do you want to invest each period')}
          label={t('Periodic Contribution')}
          labelFor={`periodic-investment-${positionKey}`}
        >
          <NumericInput
            id={`periodic-investment-${positionKey}`}
            min="0"
            placeholder="Periodic Contribution"
            value={regularAddition}
            onValueChange={handleChange(positionKey, 'regularAddition')}
          />
        </FormGroup>
        <FormGroup inline helperText={t('Interval for periodic contributions')} label={t('Contribution Interval')}>
          <HTMLSelect
            value={additionPeriod}
            onChange={(e) => {
              handleChange(positionKey, 'additionPeriod')(e.target.value);
            }}
          >
            <option value="weekly">{t('Weekly')}</option>
            <option value="bi_weekly">{t('Bi-weekly')}</option>
            <option value="monthly">{t('Monthly')}</option>
            <option value="quarterly">{t('Quarterly')}</option>
            <option value="semi_annually">{t('Semi-annually')}</option>
            <option value="yearly">{t('Yearly')}</option>
          </HTMLSelect>
        </FormGroup>
        <FormGroup
          inline
          helperText={t('Expected return in percent')}
          label={t('Expected Return')}
          labelFor="text-input"
        >
          <NumericInput
            min={0.00001}
            max={100}
            value={interestRate}
            placeholder={t('Expected return in percent')}
            className={styles.expectedReturnInput}
            // labelRenderer={(val) => `${Math.round(val * 10) / 10}%`}
            rightElement={<h3 style={{ marginTop: '5px', marginRight: '5px', fontWeight: '500' }}>%</h3>}
            onValueChange={handleChange(positionKey, 'interestRate')}
          />
        </FormGroup>
        <FormGroup
          inline
          helperText={t('How long you want to invest')}
          label={t('Term (In Years)')}
          labelFor={`term-${positionKey}`}
        >
          <NumericInput
            id={`term-${positionKey}`}
            min="1"
            max="60"
            placeholder={t('Term (In Years)')}
            style={termInYears > 50 ? { color: '#ff4646' } : null}
            value={termInYears}
            onValueChange={handleChange(positionKey, 'termInYears')}
          />
        </FormGroup>
        {showCompoundType && (
          <FormGroup inline helperText={t('Compounding type')} label={t('Compounding')}>
            <HTMLSelect
              value={interestCompoundedType}
              onChange={(e) => {
                handleChange(positionKey, 'interestCompoundedType')(e.target.value);
              }}
            >
              <option value="weekly">{t('Weekly')}</option>
              <option value="bi_weekly">{t('Bi-weekly')}</option>
              <option value="monthly">{t('Monthly')}</option>
              <option value="quarterly">{t('Quarterly')}</option>
              <option value="semi_annually">{t('Semi-annually')}</option>
              <option value="yearly">{t('Yearly')}</option>
            </HTMLSelect>
          </FormGroup>
        )}
      </div>
    </>
  );
};
