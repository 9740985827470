/* eslint-disable react/no-danger */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import DOMPurify from 'dompurify';
import { Col } from 'react-flexbox-grid';
import { Card } from '@blueprintjs/core';
import styles from './styles.module.scss';

const Post = ({ post, loader }) => {
  if (loader || !post || !post.id) {
    return (
      <Card className={styles.postCard}>
        <h2
          className={(styles.postTitle, 'bp3-skeleton')}
          style={{ borderBottom: '1px black solid' }}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize('Lorem ipsum dolor sit amet, consectetur'),
          }}
        />
        <div className={styles.postContent}>
          <div className={(styles.postThumbImg, 'bp3-skeleton')} style={{ width: '300px', height: '150px' }} />
          <div className={styles.postExcerpt}>
            <p className="bp3-skeleton" style={{ marginLeft: '20px' }}>
              Lorem ipsum dolor sit amet
            </p>
            <div
              className="content bp3-skeleton"
              style={{ marginLeft: '20px', minHeight: '100px' }}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eget tortor felis. Fusce dapibus metus in dapibus mollis. Quisque eget ex diam.'
                ).replace('More', ''),
              }}
            />
          </div>
        </div>
      </Card>
    );
  }

  const date = new Date(Date.parse(post.date)).toLocaleDateString('MK-mk');
  const time = new Date(Date.parse(post.date)).toLocaleTimeString('MK-mk');

  return (
    <Col key={post.id} className={styles.postColumn}>
      <Card className={styles.postCard}>
        <h2
          className={styles.postTitle}
          style={{ borderBottom: '1px black solid' }}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(post.title.rendered),
          }}
        />

        <div className={styles.postContent}>
          <p>{`${date} ${time}`}</p>
          <img src={post.jetpack_featured_media_url} alt={post.title.rendered} className={styles.postThumbImg} />
          <div className={styles.postHtml}>
            <div
              className="content"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(post.content.rendered, {
                  // REMOVE_TEXT: ['script'],
                }),
              }}
            />
          </div>
        </div>
      </Card>
    </Col>
  );
};

export default Post;
