/* eslint-disable no-undef */
/* eslint-disable class-methods-use-this */
import React, { useState, useEffect, useContext } from 'react';
import { ResponsiveLine } from '@nivo/line';
import { ThemeContext } from '../../../../context/ThemeProvider';
import { BACKEND_API } from '../../../../global/environment';
import { tooltip } from '../../../../components/LineChart/helper';
// import Loader from '../../../../components/loader';

function AllFundsPriceChart() {
  const { theme } = useContext(ThemeContext);
  const [fundsData, setFundsData] = useState([]);
  // eslint-disable-next-line no-undef

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;
    const fundNamesUrl = `${BACKEND_API}fundsMeta/prices`;
    fetch(fundNamesUrl, { signal })
      .then((results) => results.json())
      .then((jsonData) => {
        setFundsData(jsonData);
      })
      .catch(() => {});
    return function cleanup() {
      abortController.abort();
    };
  }, []);

  return (
    <ResponsiveLine
      data={fundsData}
      margin={{ top: 15, right: 200, bottom: 70, left: 90 }}
      xScale={{ type: 'point' }}
      yScale={{
        type: 'linear',
        min: 'auto',
        max: 'auto',
        stacked: false,
        reverse: false,
      }}
      blendMode={theme === 'dark' ? 'normal' : 'multiply'}
      stacked={false}
      axisTop={null}
      enableGridX={false}
      axisRight={null}
      enablePoints={false}
      colors={{ scheme: 'category10' }}
      useMesh
      tooltip={({ point }) => tooltip(point, theme, 'Date')}
      axisBottom={{
        orient: 'bottom',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Risk (Volatility %)',
        legendPosition: 'middle',
        // tickValues: ['2019-09-13'],
        // tickValues : () => {
        //   // const data = [...fundsData];
        //  return ['2019-09-13'];
        // },
        legendOffset: 46,
      }}
      axisLeft={{
        orient: 'left',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Average yearly return (%)',
        legendPosition: 'middle',
        legendOffset: -60,
      }}
      legends={[
        {
          anchor: 'bottom-right',
          direction: 'column',
          justify: false,
          translateX: 220,
          translateY: 0,
          itemWidth: 200,
          itemHeight: 20,
          itemsSpacing: 5,
          itemDirection: 'left-to-right',
          symbolSize: 12,
          symbolShape: 'circle',
          onClick(event, legendItem) {
            const data = [...fundsData];
            data.forEach((fund, key) => {
              if (fund.id === event.id) {
                fund.data = [];
                fund.id = `(${fund.id})`;
              }
            });
            setFundsData(data);
          },
        },
      ]}
    />
  );
}

export default React.memo(AllFundsPriceChart);
