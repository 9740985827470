import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import Boost from 'highcharts/modules/boost';
import Indicators from 'highcharts/indicators/indicators-all';
import { Colors } from '@blueprintjs/core';
import _ from 'lodash';
import { ThemeContext } from '../../../../context/ThemeProvider';
import { BACKEND_API } from '../../../../global/environment';
import Loader from '../../../../components/loader';
import './style.css';

// init the module
Indicators(Highcharts);
Boost(Highcharts);

const HighChartsTaChart = ({ fundId }) => {
  const [fundsData, setFundsData] = useState({ data: [] });
  const { i18n, t } = useTranslation();
  const { t: tFunds } = useTranslation('fundNames');
  const { theme } = useContext(ThemeContext);
  const [grayColor, setGrayColor] = useState(theme === 'dark' ? Colors.DARK_GRAY1 : Colors.LIGHT_GRAY4);

  useEffect(() => {
    setGrayColor(theme === 'dark' ? Colors.DARK_GRAY1 : Colors.LIGHT_GRAY4);
  }, [theme]);
  // eslint-disable-next-line no-undef

  const setZoomButtons = () => {
    // As highcharts doesn't allow dynamic setup of rangeSelector.buttons this is a workaround :(
    setInterval(() => {
      if (
        _.get(
          document.getElementsByClassName('highcharts-range-selector-buttons'),
          '[0].children[1].children[1].textContent'
        )
      ) {
        try {
          const zoomBtn1 = document.getElementsByClassName('highcharts-range-selector-buttons')[0].children[1]
            .children[1];
          const zoomBtn2 = document.getElementsByClassName('highcharts-range-selector-buttons')[0].children[2]
            .children[1];
          const zoomBtn3 = document.getElementsByClassName('highcharts-range-selector-buttons')[0].children[3]
            .children[1];
          const zoomBtn4 = document.getElementsByClassName('highcharts-range-selector-buttons')[0].children[4]
            .children[1];
          const zoomBtn5 = document.getElementsByClassName('highcharts-range-selector-buttons')[0].children[5]
            .children[1];
          const zoomBtn6 = document.getElementsByClassName('highcharts-range-selector-buttons')[0].children[6]
            .children[1];

          zoomBtn1.textContent = t('1m');
          zoomBtn2.textContent = t('3m');
          zoomBtn3.textContent = t('6m');
          zoomBtn4.textContent = t('YTD');
          zoomBtn5.textContent = t('1y');
          zoomBtn6.textContent = t('All');

          document.getElementsByClassName('highcharts-range-selector-buttons')[0].children[6].children[0].style.width =
            i18n.language === 'mk' ? '42px' : '32px';
          // eslint-disable-next-line no-empty
        } catch (e) {}
      }
    }, 1000);
  };

  const setLanguage = () => {
    if (i18n.language !== 'mk') {
      Highcharts.setOptions({
        lang: {
          months: [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
          ],
          weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
          shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          rangeSelectorZoom: 'Zoom',
          rangeSelectorFrom: 'From',
          rangeSelectorTo: 'To',
        },
      });
    } else {
      Highcharts.setOptions({
        lang: {
          months: [
            'јануари',
            'февруари',
            'март',
            'април',
            'мај',
            'јуни',
            'јули',
            'август',
            'септември',
            'октомври',
            'ноември',
            'декември',
          ],
          weekdays: ['понеделник', 'вторник', 'среда', 'четврток', 'петок', 'сабота', 'недела'],
          shortMonths: ['јан', 'феб', 'мар', 'апр', 'мај', 'јун', 'јул', 'авг', 'сеп', 'окт', 'нов', 'дек'],
          rangeSelectorZoom: 'Зум',
          rangeSelectorFrom: 'Од',
          rangeSelectorTo: 'До',
        },
      });
    }
    setZoomButtons();
  };

  useEffect(() => {
    setFundsData({ data: [] });
    const abortController = new AbortController();
    const { signal } = abortController;
    const fundNamesUrl = `${BACKEND_API}funds/${fundId}/price`;
    fetch(fundNamesUrl, { signal })
      .then((results) => results.json())
      .then((jsonData) => {
        setFundsData({ ...jsonData, data: jsonData.data.map((o) => [Date.parse(o.x), o.y]) });
      })
      .catch(() => {});
    return function cleanup() {
      abortController.abort();
    };
  }, [fundId]);

  useEffect(() => {
    setLanguage();
    setFundsData(() => ({ ...fundsData }));
  }, [i18n.language]);

  //   const buttonFillColor = theme === 'dark' ? '#597488' : '#374854';

  const options = {
    theme: 'db',
    allowChartUpdate: false,
    rangeSelector: {
      selected: 1,
      buttonTheme: {
        // styles for the buttons
        fill: 'none',
        stroke: 'none',
        'stroke-width': 0,
        r: 3,
        style: {
          color: '#374854',
          fontWeight: 'bold',
        },
        states: {
          hover: {},
          select: {
            fill: '#374854',
            style: {
              color: 'white',
            },
          },
        },
      },
    },
    chart: {
      height: 800,
      backgroundColor: 'transparent',
    },
    tooltip: { backgroundColor: grayColor },
    xAxis: { type: 'datetime' },
    yAxis: {
      // plotLines: [
      //   {
      //     id: 'firstPrice',
      //     value: 53,
      //     width: 2,
      //     color: 'green',
      //     dashStyle: 'dash',
      //     label: {
      //       text: 'Latest value ',
      //       align: 'right',
      //       y: 12,
      //       x: 0,
      //     },
      //   },
      // ],
    },
    legend: {
      enabled: true,
    },
    plotOptions: {
      series: {
        showInLegend: true,
        animation: false,
      },
    },
    colors: ['#058DC7', '#50B432', '#ED561B', '#DDDF00', '#24CBE5', '#64E572', '#FF9655', '#FFF263', '#6AF9C4'],
    series: [
      {
        name: tFunds(fundsData.name),
        type: 'line',
        id: 'fund',
        data: fundsData.data,
      },
      {
        visible: false,
        type: 'ema',
        linkedTo: 'fund',
        params: {
          period: 5,
        },
      },
      {
        visible: false,
        type: 'ema',
        linkedTo: 'fund',
        params: {
          period: 10,
        },
      },
      {
        visible: false,
        type: 'ema',
        linkedTo: 'fund',
        params: {
          period: 20,
        },
      },
      {
        visible: true,
        type: 'ema',
        linkedTo: 'fund',
        params: {
          period: 30,
        },
      },
      {
        visible: false,
        type: 'ema',
        linkedTo: 'fund',
        params: {
          period: 50,
        },
      },
      {
        visible: false,
        type: 'ema',
        linkedTo: 'fund',
        params: {
          period: 100,
        },
      },
      {
        visible: false,
        type: 'ema',
        linkedTo: 'fund',
        params: {
          period: 200,
        },
      },
      {
        visible: false,
        type: 'ema',
        linkedTo: 'fund',
        params: {
          period: 300,
        },
      },
      {
        visible: false,
        type: 'bb',
        linkedTo: 'fund',
      },
    ],
  };

  if (!fundsData.data[1]) {
    return <Loader />;
  }

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} constructorType="stockChart" options={options} />
    </div>
  );
};

export default HighChartsTaChart;
