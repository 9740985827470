/* eslint-disable class-methods-use-this */
import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { Card } from '@blueprintjs/core';
import GeneralMarketStdAnomalies from './GeneralMarketStdAnomalies';

function GeneralIndicators() {
  return (
    <Row>
      <Col lg={12}>
        <Card>
          <GeneralMarketStdAnomalies />
        </Card>
      </Col>
    </Row>
  );
}

export default GeneralIndicators;
