/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import DOMPurify from 'dompurify';
import { Col } from 'react-flexbox-grid';
import { Card, H2 } from '@blueprintjs/core';
import Emitter from '../../../../services/emitter';
import styles from './styles.module.scss';

const PostPreview = ({ post, loader }) => {
  const date = new Date(Date.parse(post.date)).toLocaleDateString('MK-mk');
  const time = new Date(Date.parse(post.date)).toLocaleTimeString('MK-mk');

  if (loader) {
    return (
      <Card className={styles.postPreviewCard}>
        <h2
          className={(styles.postTitle, 'bp3-skeleton')}
          style={{ borderBottom: '1px black solid' }}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize('Lorem ipsum dolor sit amet, consectetur'),
          }}
        />
        <div className={styles.postContent}>
          <div className={(styles.postThumbImg, 'bp3-skeleton')} style={{ width: '300px', height: '150px' }} />
          <div className={styles.postExcerpt}>
            <p className="bp3-skeleton" style={{ marginLeft: '20px' }}>
              Lorem ipsum dolor sit amet
            </p>
            <div
              className="content bp3-skeleton"
              style={{ marginLeft: '20px', minHeight: '100px' }}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eget tortor felis. Fusce dapibus metus in dapibus mollis. Quisque eget ex diam.'
                ).replace('More', ''),
              }}
            />
          </div>
        </div>
      </Card>
    );
  }

  return (
    <Col key={post.id}>
      <Card className={styles.postPreviewCard}>
        <h2
          className={styles.postTitle}
          style={{ borderBottom: '1px black solid' }}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(post.title.rendered),
          }}
          onClick={() => Emitter.emit('NAVIGATE_TO_POST', post.id)}
        />
        <div className={styles.postContent}>
          <img
            src={post.jetpack_featured_media_url}
            alt={post.title.rendered}
            className={styles.postThumbImg}
            onClick={() => Emitter.emit('NAVIGATE_TO_POST', post.id)}
          />
          <div className={styles.postExcerpt}>
            <p>{`${date} ${time}`}</p>
            <div
              className="content"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(post.excerpt.rendered, {
                  ALLOWED_TAGS: ['b', 'q', 'i'],
                  ALLOWED_ATTR: ['style'],
                  KEEP_TEXT: true,
                  REMOVE_TEXT: ['script'],
                }).replace('More', ''),
              }}
            />
          </div>
        </div>
      </Card>
    </Col>
  );
};

export default PostPreview;
