/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useContext } from 'react';
import { Callout } from '@blueprintjs/core';
import { useParams, useHistory, useLocation } from 'react-router';
import WPAPI from 'wpapi';
import { Row, Col, Grid } from 'react-flexbox-grid';
import { useTranslation } from 'react-i18next';
import { BACKEND_API } from '../../global/environment';
import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import HorizontalMenu from './components/HorizontalMenu';
import BlogGrid from './components/BlogGrid';
import Emitter from '../../services/emitter';
import { BLOG } from '../../global/routes';
import { BlogContext } from '../../context/BlogProvider';

const Blog = () => {
  const { i18n } = useTranslation();
  const wpcom = WPAPI.site('https://public-api.wordpress.com');
  const [activeTab, setActiveTab] = useState(0);
  const [searchString, setSearchString] = useState('');
  const [mbi10Data, setMbi10Data] = useState([]);
  const [ombData, setOmbData] = useState([]);
  const [currentPage, setCurrrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  // const [postsCount, setPostsCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [currentPost, setCurrentPost] = useState(null);
  const [currentPostId, setCurrentPostId] = useState(null);
  const { slug } = useParams();
  const history = useHistory();
  const location = useLocation();
  const { categories, posts, setPosts, setCategories } = useContext(BlogContext);

  const searchPosts = (_searchString) => {
    setLoading(true);
    setCurrentPost(null);
    setCurrentPostId(null);

    let localSearchString = searchString;
    if (_searchString) {
      localSearchString = _searchString;
    }

    wpcom
      .posts()
      .search(localSearchString || null)
      .perPage(2)
      .page(currentPage)
      .namespace('wp/v2/sites/fondovi.wordpress.com')
      .get()
      .then((data) => {
        setPosts(data);
        if (data.length > 0 && data._paging) {
          setPageCount(data._paging.totalPages);
        } else {
          setPageCount(1);
        }
        // setPostsCount(data._paging.total);
        setLoading(false);
      });
  };

  useEffect(() => {
    searchPosts();
  }, [currentPage]);

  useEffect(() => {
    if (categories.length < 1) {
      wpcom
        .categories()
        .namespace('wp/v2/sites/fondovi.wordpress.com')
        .get()
        .then((data) => {
          setCategories(data.filter((c) => c.slug !== 'uncategorized'));
        });
    }
    Emitter.on('NAVIGATE_TO_POST', (postId) => {
      setCurrentPostId(postId);
      Emitter.emit('RESET_SEARCH_VALUE', null);
      setSearchString('');
    });

    Emitter.on('SEARCH_POST', (_searchString) => {
      setCurrrentPage(1);
      setSearchString(_searchString);
      searchPosts(_searchString);
      if (location.pathname !== BLOG) {
        // setTimeout(() => {
        // window.history.replaceState(null, null, BLOG);
        // history.replace({ pathname: BLOG });
        // }, 5000);
      }
    });

    return () => {
      Emitter.off('NAVIGATE_TO_POST');
      Emitter.off('SEARCH_POST');
    };
  }, []);

  useEffect(() => {
    if (currentPostId) {
      const _currentPost = posts.find((p) => p.id === currentPostId);
      history.push(`${BLOG}/post/${_currentPost.slug}`);
    }
  }, [currentPostId]);

  useEffect(() => {
    if (slug) {
      const slugPost = posts.find((p) => p.slug === slug);
      if (slugPost && slugPost.id) {
        setCurrentPost(slugPost);
      } else {
        wpcom
          .posts()
          .slug(slug)
          .namespace('wp/v2/sites/fondovi.wordpress.com')
          .get()
          .then((post) => {
            if (post && post[0] && post[0].id) {
              setCurrentPost(post[0]);
            } else {
              setPosts([]);
              setCurrentPost(null);
              setPageCount(1);
            }
          });
      }
    }
  }, [slug]);

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;

    const mbi10Response = `${BACKEND_API}index/mbi10`;
    fetch(mbi10Response, { signal })
      .then((results) => results.json())
      .then((jsonData) => {
        setMbi10Data(jsonData);
      })
      .catch(() => {});

    const ombResponse = `${BACKEND_API}index/omb`;
    fetch(ombResponse, { signal })
      .then((results) => results.json())
      .then((jsonData) => {
        setOmbData(jsonData);
      })
      .catch(() => {});
  }, []);

  return (
    <>
      <Header />
      <Grid>
        <Row>
          <Col lg={12}>
            <Row center="lg">
              <Col lg={12}>
                {i18n.language !== 'mk' && (
                  <Callout
                    intent="primary"
                    title="The blog content is not available in English"
                    style={{ marginTop: '25px' }}
                  />
                )}
                <HorizontalMenu activeTab={activeTab} onTabChange={setActiveTab} data={categories} />
                <BlogGrid
                  posts={posts}
                  mbi10Data={mbi10Data}
                  ombData={ombData}
                  pageCount={pageCount}
                  onPageChange={setCurrrentPage}
                  currentPage={currentPage}
                  loader={loading}
                  currentPost={currentPost}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Grid>

      <Footer />
    </>
  );
};

export default Blog;
