import React from 'react';
import { Button, Icon } from '@blueprintjs/core';
import _ from 'lodash';
import styles from './styles.module.scss';

const Pagination = ({ currentPage, pageCount, onPageChange }) => {
  const pageButtons = _.range(1, pageCount + 1).map((e) => (
    <Button
      text={e}
      disabled={currentPage === e}
      onClick={() => onPageChange(e)}
      className={styles.pageBtn}
      small
      key={e}
    />
  ));
  return (
    <div className={styles.paginationComponent}>
      <Button
        small
        icon="double-chevron-left"
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className={styles.previousBtn}
      />
      {pageButtons}
      <Button
        small
        icon="double-chevron-right"
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === pageCount}
        className={styles.nextBtn}
      />
    </div>
  );
};

export default Pagination;
