/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import {
  Alignment,
  AnchorButton,
  Classes,
  Navbar,
  NavbarGroup,
  NavbarHeading,
  NavbarDivider,
  Colors,
  Popover,
  Button,
  Menu,
  MenuItem,
} from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import { HOME_PAGE, OPEN_END_FUND_ANALYSIS, BLOG } from '../../../global/routes';
import { ThemeContext } from '../../../context/ThemeProvider';
import styles from './styles.module.scss';
import { UKFlag, MacedonianFlag } from './LanguageFlags';

const Header = ({ children }) => {
  const { t, i18n } = useTranslation();
  const { theme, toggleTheme } = useContext(ThemeContext);
  const history = useHistory();
  const [blueColor, setBlueColor] = useState(Colors.RED1);
  const [darkGrayColor, setDarkGrayColor] = useState(Colors.DARK_GRAY1);
  const [orangeColor, setGoldColor] = useState(Colors.ORANGE1);
  const [width, setWidth] = useState(1920);

  const setWidthWrap = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    setWidth(window.innerWidth);
    window.addEventListener('resize', setWidthWrap);
    return () => {
      window.removeEventListener('resize', setWidthWrap);
    };
  }, []);

  useEffect(() => {
    setBlueColor(theme === 'dark' ? Colors.BLUE5 : Colors.BLUE1);
    setDarkGrayColor(theme === 'dark' ? Colors.GRAY5 : Colors.DARK_GRAY1);
    setGoldColor(theme === 'dark' ? Colors.ORANGE5 : Colors.ORANGE1);
  }, [theme]);

  return (
    <Navbar className={(Classes.LIGHT, styles.navBar)}>
      <NavbarGroup align={Alignment.CENTER}>
        <NavbarHeading className={styles.navbarHeading}>
          <div
            className={styles.logo}
            style={{ color: darkGrayColor }}
            onClick={() => history.push(OPEN_END_FUND_ANALYSIS)}
          >
            {width > 1366 ? 'Investment' : 'I'}
            <span style={{ color: blueColor }}>{width > 1366 ? 'Forge' : 'F'}</span>
          </div>
        </NavbarHeading>
        <NavbarDivider />
        <AnchorButton
          className={styles.menuItemPadding}
          onClick={() => history.push(OPEN_END_FUND_ANALYSIS)}
          text={width > 1366 ? t('oif_analysis') : t('oif_analysis_short')}
          minimal
          active={history.location.pathname === OPEN_END_FUND_ANALYSIS}
          icon="chart"
        />
        <AnchorButton
          onClick={() => history.push(HOME_PAGE)}
          text={t('Investment Calculator')}
          minimal
          active={history.location.pathname === HOME_PAGE}
          icon="calculator"
        />
        <AnchorButton
          onClick={() => history.push(BLOG)}
          text={t('blog')}
          minimal
          active={history.location.pathname.startsWith(BLOG)}
          icon="label"
          style={{ color: orangeColor }}
          className={styles.blogButton}
        />
        {children}
        <div className={styles.darkModeBtn}>
          <Popover
            content={
              <Menu>
                <MenuItem icon={MacedonianFlag} text="Македонски" onClick={() => i18n.changeLanguage('mk')} />
                <MenuItem icon={UKFlag} text="English" onClick={() => i18n.changeLanguage('en')} />
              </Menu>
            }
          >
            <Button minimal icon="translate" />
          </Popover>
          <AnchorButton text="" minimal onClick={toggleTheme} icon={theme === 'light' ? 'flash' : 'moon'} />
        </div>
      </NavbarGroup>
    </Navbar>
  );
};

export default withRouter(React.memo(Header));
