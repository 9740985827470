/* eslint-disable max-len */
import React, { useContext, useState, useEffect } from 'react';
import { Colors, Icon } from '@blueprintjs/core';
import MiniChart from '../MiniChart';
import { ThemeContext } from '../../../../context/ThemeProvider';
import { formatDate } from '../../../../utils/Date';
import styles from './styles.module.scss';

const IndexCard = ({ data }) => {
  const { theme } = useContext(ThemeContext);
  const [redColor, setRedColor] = useState(Colors.RED1);
  const [greenColor, setGreenColor] = useState(Colors.GREEN1);
  const [grayColor, setGrayColor] = useState(Colors.GRAY2);

  useEffect(() => {
    setRedColor(theme === 'dark' ? Colors.RED5 : Colors.RED1);
    setGreenColor(theme === 'dark' ? Colors.GREEN5 : Colors.GREEN1);
    setGrayColor(theme === 'dark' ? Colors.DARK_GRAY5 : Colors.GRAY2);
  }, [theme]);

  if (!data || !data.data || !data.data[0]) {
    return <></>;
  }

  const pctChange = (((data.data.slice(-1)[0].y - data.data.slice(-2)[0].y) / data.data.slice(-2)[0].y) * 100).toFixed(
    2
  );
  const change = (data.data.slice(-1)[0].y - data.data.slice(-2)[0].y).toFixed(2).toLocaleString('mk-MK');
  const date = formatDate(new Date(Date.parse(data.data.slice(-1)[0].x)));
  let backgroundColor = grayColor;

  if (pctChange < 0) {
    backgroundColor = redColor;
  }

  if (pctChange > 0) {
    backgroundColor = greenColor;
  }

  return (
    <div style={{ backgroundColor }} className={styles.indexCard}>
      <div style={{ width: '60%', display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'inline-flex' }}>
          <h3>{data.name}</h3>
          <span style={{ fontSize: '13px', alignSelf: 'flex-end', marginLeft: '10px', fontWeight: '500' }}>
            {change}
          </span>
        </div>
        <div style={{ display: 'inline-flex', marginTop: '10px' }}>
          <span>{data.data.slice(-1)[0].y.toLocaleString('mk-MK')}</span>
          <span style={{ alignSelf: 'flex-end', marginLeft: '10px' }}>{pctChange}%</span>
        </div>
        <div style={{ display: 'inline-flex', marginTop: '10px' }}>
          <Icon icon="calendar" iconSize={15} />
          <span style={{ marginLeft: '10px' }}>{date}</span>
        </div>
      </div>
      <div style={{ width: '40%', marginTop: '5px' }}>
        <MiniChart data={data} />
      </div>
    </div>
  );
};

export default IndexCard;
