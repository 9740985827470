/* eslint-disable class-methods-use-this */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-flexbox-grid';
import { Card, Button } from '@blueprintjs/core';
import { BACKEND_API } from '../../../../global/environment';
import AnomalySummary from './AnomalySummary';

import AnomalyChartUPlot from './AnomalyChartUPlot';
// import { ThemeContext } from '../../../../context/ThemeProvider';
import Loader from '../../../../components/loader';
import styles from './styles.module.scss';

function Anomaly({ fundId }) {
  // const { theme } = useContext(ThemeContext);
  const { t } = useTranslation();
  const [anomalyData, setAnomalyData] = useState({});
  const [expanded, setExpanded] = useState(false);
  const [noData, setNoData] = useState(false);

  useEffect(() => {
    const fetchFundAnomalies = async () => {
      const anomalyUrl = `${BACKEND_API}funds/${fundId}/priceAnomalies`;
      try {
        const response = await fetch(anomalyUrl);
        if (response.status !== 200) {
          setNoData(true);
        }
        const jsonData = await response.json();
        setAnomalyData(jsonData);
      } catch (err) {
        setNoData(true);
      }
    };
    fetchFundAnomalies();
  }, [fundId]);

  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, [expanded]);

  if (noData) {
    return <h2 style={{ textAlign: 'center' }}>{t('fund_anomaly_data_not_found')}</h2>;
  }

  if (!anomalyData.id) {
    return <Loader />;
  }

  return (
    <Row>
      {!expanded && (
        <Col lg={6}>
          <Card className={styles.anomalyCard}>
            <h2>{t('Anomalies Summary (Experimental)')}*</h2>
            <AnomalySummary data={anomalyData} />
            <div style={{ marginTop: '10px' }}>* {t('anomaly_description_text')}</div>
          </Card>
        </Col>
      )}
      <Col lg={expanded ? 12 : 6}>
        <Card className={styles.anomalyCard} id="anomalyChart">
          <div style={{ display: 'flex' }}>
            <h2>{t('Anomalies Graph (Experimental)')}</h2>
            <Button
              icon={expanded ? 'merge-columns' : 'split-columns'}
              style={{ marginLeft: 'auto' }}
              className={styles.expandBtn}
              text={expanded ? t('Collapse') : t('Expand')}
              onClick={() => setExpanded(!expanded)}
            />
          </div>
          <AnomalyChartUPlot data={anomalyData} yLabel="Unit Price" />
        </Card>
      </Col>
    </Row>
  );
}

export default Anomaly;
