import ReactGA from 'react-ga';
import { useLocalStorageState } from './useLocalStorageState';

export default (initialTheme) => {
  const [theme, setTheme] = useLocalStorageState('theme', initialTheme);
  return {
    theme,
    toggleTheme: () => {
      ReactGA.event({
        category: 'General',
        action: `Set ${theme === 'light' ? 'dark' : 'light'} theme`,
      });
      setTheme(theme === 'light' ? 'dark' : 'light');
    },
  };
};
