/* eslint-disable class-methods-use-this */
import React, { useContext } from 'react';
import { Card, ButtonGroup, Divider, Tabs, Tab, Icon } from '@blueprintjs/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faBraille, faListUl } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import FundsSelector from '../FundsSelector';
import styles from './styles.module.scss';
import { ThemeContext } from '../../../../context/ThemeProvider';

function HorizontalMenu({ activeFund, onTabChange, activeTab }) {
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);
  const iconColor = theme === 'dark' ? '#a7b6c2' : '#5c7080';

  if (!activeTab) {
    return <></>;
  }

  return (
    <Card className={styles.secondaryMenu}>
      <ButtonGroup>
        <FundsSelector />
        <Divider className={styles.menuDivider} />
        <Tabs
          id="TabsExample"
          selectedTabId={activeTab}
          onChange={(e) => {
            onTabChange(e);
          }}
        >
          {activeFund > 0 && (
            <Tab id="general">
              <Icon icon="info-sign" iconSize={15} style={{ paddingRight: '2px', color: iconColor }} />{' '}
              {t('General Info')}
            </Tab>
          )}
          {activeFund > 0 && (
            <Tab id="price" panelClassName="ember-panel">
              <Icon icon="series-search" iconSize={15} style={{ paddingRight: '2px', color: iconColor }} />
              {t('Price Analysis')}
            </Tab>
          )}
          {activeFund < 1 && (
            <Tab id="overview" panelClassName="ember-panel">
              <FontAwesomeIcon
                style={{ paddingRight: '4px', color: iconColor, fontSize: '15px', marginBottom: '-1px' }}
                icon={faListUl}
              />
              {t('Overview')}
            </Tab>
          )}
          {activeFund < 1 && (
            <Tab id="risk" panelClassName="ember-panel">
              <FontAwesomeIcon
                style={{ paddingRight: '4px', color: iconColor, fontSize: '15px', marginBottom: '-1px' }}
                icon={faExclamationTriangle}
              />
              {t('Risk Analysis')}
            </Tab>
          )}
          {activeFund < 1 && (
            <Tab id="generalIndicators" panelClassName="ember-panel">
              <Icon icon="layout-hierarchy" iconSize={15} style={{ paddingRight: '4px', color: iconColor }} />
              {t('general_indicators')}
            </Tab>
          )}
          {activeFund > 0 && (
            <Tab id="anomaly" panelClassName="ember-panel">
              <FontAwesomeIcon
                style={{ paddingRight: '4px', color: iconColor, fontSize: '15px', marginBottom: '-1px' }}
                icon={faBraille}
              />
              {t('Price Anomalies')}
            </Tab>
          )}
          {activeFund > 0 && (
            <Tab id="simulation" panelClassName="ember-panel">
              <Icon icon="curved-range-chart" iconSize={15} style={{ paddingRight: '2px', color: iconColor }} />
              {t('Simulations')}
            </Tab>
          )}
          {activeFund < 1 && (
            <Tab id="netValue" panelClassName="ember-panel">
              <Icon icon="timeline-bar-chart" iconSize={15} style={{ paddingRight: '2px', color: iconColor }} />
              {t('Net Value')}
            </Tab>
          )}
        </Tabs>
      </ButtonGroup>
    </Card>
  );
}

export default React.memo(HorizontalMenu);
