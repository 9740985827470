/* eslint-disable no-underscore-dangle */
import React, { useEffect, useRef, useContext, useState } from 'react';
import { ResponsiveLine } from '@nivo/line';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { ThemeContext } from '../../context/ThemeProvider';
import { tooltip } from './helper';

const LineChart = ({ data }) => {
  const { theme } = useContext(ThemeContext);
  const [investmentData, setInvestmentData] = useState([]);
  const [numberOfDataPoints, setNumberOfDataPoints] = useState(0);
  const xRotationRef = useRef(0);
  const { t, i18n } = useTranslation();
  const dateLabel = t('Date');
  const valueLabel = t('Value');

  useEffect(() => {
    if (data[0] && data[0].data && data[0].data.length > 25) {
      xRotationRef.current = data[0].data.length * -1;
    } else {
      xRotationRef.current = 0;
    }
    if (data[0]) {
      let maxYearLen = 0;
      const _data = _.cloneDeep(data);
      _data.forEach((investment) => {
        if (!investment._id) {
          investment._id = investment.id;
        }
        investment.id = `${t('Investment')} ${investment._id}`;
        if (investment.data.length > maxYearLen) {
          maxYearLen = investment.data.length;
        }
      });
      setNumberOfDataPoints(maxYearLen);
      setInvestmentData(_data);
    }
  }, [data, theme, t]);

  if (!investmentData[0]) {
    return <h4 style={{ textAlign: 'center', paddingTop: '15%' }}>Loading.....</h4>;
  }

  return (
    <ResponsiveLine
      data={investmentData}
      margin={{ top: 20, right: 10, bottom: 60, left: 60 }}
      // xScale={{ type: 'point' }}
      xScale={{
        type: 'time',
        format: '%Y',
        precision: 'year',
      }}
      yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
      stacked={false}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        orient: 'bottom',
        tickSize: 0,
        tickPadding: 5,
        tickRotation: xRotationRef.current,
        legend: t('Period'),
        legendOffset: 30,
        legendPosition: 'middle',
        format: '%Y',
        tickValues: 'every 2 years',
      }}
      axisLeft={{
        orient: 'left',
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,
        legend: t('Value'),
        legendOffset: -55,
        legendPosition: 'middle',
      }}
      colors={{ scheme: 'category10' }}
      enablePoints
      pointSize={10}
      pointColor={{ theme: 'background' }}
      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor' }}
      pointLabel="value"
      pointLabelYOffset={-12}
      useMesh
      enableGridX={false}
      tooltip={({ point }) => {
        const tooltipDataPoint = {
          id: point.id,
          index: point.index,
          serieColor: point.serieColor,
          serieId: point.serieId,
          data: [],
        };
        tooltipDataPoint.data.xFormatted = point.data.x.getFullYear();
        tooltipDataPoint.data.yFormatted = point.data.yFormatted;
        return tooltip(tooltipDataPoint, theme, dateLabel, valueLabel, numberOfDataPoints);
      }}
      legends={[
        {
          anchor: 'bottom',
          direction: 'row',
          justify: false,
          translateX: 0,
          translateY: 60,
          itemsSpacing: i18n.language === 'mk' ? 25 : 15,
          itemDirection: 'left-to-right',
          itemWidth: 100,
          itemHeight: 20,
          itemOpacity: 1,
          symbolSize: 12,
          symbolShape: 'circle',
          symbolBorderColor: 'rgba(0, 0, 0, .5)',
        },
      ]}
    />
  );
};

export default React.memo(LineChart);
