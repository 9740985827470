/* eslint-disable class-methods-use-this */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Icon } from '@blueprintjs/core';
import { Row, Col } from 'react-flexbox-grid';
import { BACKEND_API } from '../../../../../global/environment';
import Loader from '../../../../../components/loader';
import styles from './styles.module.scss';
import { formatDate } from '../../../../../utils/Date';

function TrailingReturns({ fundId }) {
  const { t } = useTranslation();
  const [fundData, setFundData] = useState([]);
  const [width, setWidth] = useState(1920);
  const [intervals, setIntervals] = useState({});

  const setWidthWrap = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    setWidth(window.innerWidth);
    window.addEventListener('resize', setWidthWrap);
    return () => {
      window.removeEventListener('resize', setWidthWrap);
    };
  }, []);

  useEffect(() => {
    setIntervals({
      '1D': t('Daily'),
      // '1M': '1 Month',
      '3M': `3 ${t('months_long')}`,
      '6M': `6 ${t('months_long')}`,
      '1Y': `1 ${t('year_long')}`,
      YTD: t('Year To Date'),
      inception: t('Since Inception'),
    });
  }, [t]);

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;
    const fundTrailingReturnsUrl = `${BACKEND_API}funds/${fundId}/trailingReturns`;
    fetch(fundTrailingReturnsUrl, { signal })
      .then((results) => results.json())
      .then((jsonData) => {
        setFundData(jsonData);
      })
      .catch(() => {});
    return function cleanup() {
      abortController.abort();
    };
  }, [fundId]);

  if (!fundData['1D']) {
    return (
      <Card className={styles.mainCard}>
        <Row around="lg">
          <Col className={styles.lg1_5}>
            <div>
              <Loader />
            </div>
          </Col>
        </Row>
      </Card>
    );
  }

  const items = [];
  // todo filip
  for (const key in intervals) {
    items.push(
      <Col className={styles.lg1_5} key={key}>
        <Card className={styles.trailingCard}>
          <p className={styles.periodFont}>
            {intervals[key]} {key === 'inception' ? `(${formatDate(fundData.inceptionDate)})` : ''}
            {/* (As of {fundData.lastDate}) */}
          </p>
          <h1 className={styles.priceFont}>
            {!isNaN(fundData[key].percent) && (
              <Icon
                icon={fundData[key].percent < 0 ? 'symbol-triangle-down' : 'symbol-triangle-up'}
                iconSize={width < 1920 ? 20 : 30}
                intent={fundData[key].percent < 0 ? 'danger' : 'success'}
              />
            )}
            {key === 'inception' ? '' : fundData[key].value} ({fundData[key].percent}
            {!isNaN(fundData[key].percent) && '%'})
          </h1>
        </Card>
      </Col>
    );
  }

  return (
    <Card className={styles.mainCard}>
      <Row around="lg" className={styles.trailingReturnsRow}>
        <Col className={styles.lg1_5}>
          <Card className={styles.trailingCard}>
            <p className={styles.periodFont}>
              {t('Unit Price')} ({formatDate(fundData.lastDate)})
            </p>
            <h1 className={styles.priceFont}>{fundData.lastPrice}</h1>
          </Card>
        </Col>
        {items}
      </Row>
    </Card>
  );
}

export default React.memo(TrailingReturns);
