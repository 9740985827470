import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { calculateInvestments } from '../services/calculator/actions';
import CalculatorModel from '../services/calculator/model/Calculator';
import GlobalContext from './global';

const GlobalProvider = ({ children }) => {
  const [state, setState] = useState(() => {
    let stateObj = JSON.parse(localStorage.getItem('globalContext'));

    if (stateObj === null) {
      stateObj = { investmentCalculator: [new CalculatorModel({})] };
    }

    if (!stateObj.investmentCalculator) {
      stateObj.investmentCalculator = [new CalculatorModel({})];
    }

    if (!stateObj.calculatedResults) {
      stateObj.calculatedResults = [];
    }

    if (!stateObj.calculatedResultsChart) {
      stateObj.calculatedResultsChart = [];
    }

    if (!stateObj.calculatedResultsChart) {
      stateObj.calculatedResultsChart = [];
    }

    stateObj.setActiveCalculatorInvestmentTab = (activeCalculatorInvestmentTab) => {
      setState((prevState) => {
        const nextState = { ...prevState };
        nextState.activeCalculatorInvestmentTab = activeCalculatorInvestmentTab;
        return { ...nextState };
      });
    };

    stateObj.handleChange = (positionKey, fieldKey) => (value) => {
      if (fieldKey === 'termInYears' && parseInt(value, 10) < 1) {
        value = 1;
      }

      setState((state) => {
        state.investmentCalculator[positionKey][fieldKey] = value;
        window.debouncedCalculateInvestments();
        return {
          ...state,
        };
      });
    };

    stateObj.addFundToCalculator = () => {
      setState((prevState) => {
        const nextState = { ...prevState };
        nextState.investmentCalculator.push(new CalculatorModel({}));
        return { ...nextState };
      });
      window.debouncedCalculateInvestments();
    };

    stateObj.removeFundFromCalculator = (calculatorKey) => {
      setState((prevState) => {
        const nextState = { ...prevState };
        // delete nextState.investmentCalculator[calculatorKey];
        nextState.investmentCalculator.splice(calculatorKey, 1);
        // nextState.investmentCalculator = nextState.investmentCalculator.filter(() => true);

        return { ...nextState };
      });
      window.debouncedCalculateInvestments();
    };

    stateObj.toggleCumulativeDetails = () => {
      setState((prevState) => {
        const nextState = { ...prevState };
        nextState.showCumulativeDetails = !nextState.showCumulativeDetails;
        return { ...nextState };
      });
      window.debouncedCalculateInvestments();
    };

    stateObj.setFundAnalysisProperty = (property, value) => {
      setState((prevState) => {
        const nextState = { ...prevState };
        nextState.fundAnalysisData[property] = value;
        return { ...nextState };
      });
    };

    stateObj.activeCalculatorInvestmentTab = 0;
    stateObj.showCumulativeDetails = false;

    stateObj.fundAnalysisData = { selectedFund: 0 };

    return stateObj;
  });
  const globalContext = GlobalContext;

  useEffect(() => {
    window.debouncedCalculateInvestments();
  }, [state.investmentCalculator]);

  if (!window.debouncedCalculateInvestments) {
    window.debouncedCalculateInvestments = _.debounce(() => {
      setState((state) => {
        const stateCopy = { ...state };
        const { tableData, chartData, cumulativeInvestmentTableData } = calculateInvestments(
          stateCopy.investmentCalculator
        );
        stateCopy.calculatedResults = tableData;
        stateCopy.calculatedResultsChart = chartData;
        stateCopy.cumulativeInvestmentTableData = cumulativeInvestmentTableData;
        return {
          ...stateCopy,
        };
      });
    }, 100);
  }

  return <globalContext.Provider value={state}>{children}</globalContext.Provider>;
};

export default GlobalProvider;
