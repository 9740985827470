import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ResponsiveLine } from '@nivo/line';
import { Colors } from '@blueprintjs/core';
import { BACKEND_API } from '../../../../../global/environment';
import { tooltip } from '../../../../../components/LineChart/helper';
import { ThemeContext } from '../../../../../context/ThemeProvider';
import Loader from '../../../../../components/loader';

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const HypotheticalGrowthChart = ({ fundId }) => {
  const { t } = useTranslation();
  const { t: tFunds } = useTranslation('fundNames');
  const [fundsData, setFundsData] = useState([]);
  const [numberOfDataPoints, setNumberOfDataPoints] = useState(0);
  const { theme } = useContext(ThemeContext);
  const [blueColor, setBlueColor] = useState(Colors.BLUE1);
  const dateLabel = t('Date');
  const valueLabel = t('Value');

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;
    const riskReturnUrl = `${BACKEND_API}funds/${fundId}/hypotheticalGrowth`;

    fetch(riskReturnUrl, { signal })
      .then((results) => results.json())
      .then((jsonData) => {
        // eslint-disable-next-line no-param-reassign
        jsonData.id = jsonData.name;
        setNumberOfDataPoints(jsonData.data.length);
        setFundsData([jsonData]);
      })
      .catch(() => {});
    return function cleanup() {
      abortController.abort();
    };
  }, [fundId]);

  useEffect(() => {
    setBlueColor(theme === 'dark' ? Colors.BLUE5 : Colors.BLUE1);
  }, [theme]);

  if (!fundsData[0]) {
    return <Loader />;
  }

  return (
    <div style={{ height: '100%', width: '100%', paddingTop: '4px', paddingBottom: '25px' }}>
      <span>
        {fundsData[0].label && (
          <i>
            ({fundsData[0].label.replace('Last', t('Last')).replace('Years', t('Years')).replace('Since', t('Since'))})
          </i>
        )}
      </span>
      <ResponsiveLine
        data={fundsData}
        margin={{ top: 30, right: 11, bottom: 50, left: 50 }}
        // padding={{ bottom: 50 }}
        xScale={{
          type: 'time',
          format: '%Y-%m-%d',
          useUTC: false,
          precision: 'day',
        }}
        yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
        axisTop={null}
        axisRight={null}
        enableGridX={false}
        enablePoints={false}
        xFormat="time:%d/%m/%Y"
        axisBottom={{
          orient: 'bottom',
          format: '%d/%m/%Y',
          tickValues: 'every 2 years',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendOffset: 36,
          legendPosition: 'middle',
        }}
        axisLeft={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          // legend: 'Price',
          legendOffset: -40,
          legendPosition: 'middle',
        }}
        colors={[blueColor]}
        tooltip={({ point }) => {
          return tooltip(
            { ...point, serieId: tFunds(point.serieId) },
            theme,
            dateLabel,
            valueLabel,
            numberOfDataPoints,
            [
              {
                label: t('difference'),
                value: `${(((point.data.y - fundsData[0].data[0].y) / fundsData[0].data[0].y) * 100).toFixed(2)}%`,
              },
            ]
          );
        }}
        markers={[
          {
            axis: 'y',
            value: fundsData[0].data[0].y,
            lineStyle: { stroke: blueColor, strokeWidth: 2, strokeDasharray: '1 2' },
          },
        ]}
        useMesh
      />
    </div>
  );
};

export default React.memo(HypotheticalGrowthChart);
