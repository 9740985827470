/* eslint-disable class-methods-use-this */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BACKEND_API } from '../../../../../global/environment';

import AnomalyChartUPlot from '../../Anomaly/AnomalyChartUPlot';
// import { ThemeContext } from '../../../../context/ThemeProvider';
import Loader from '../../../../../components/loader';
// import styles from './styles.module.scss';

function GeneralIndicators() {
  // const { theme } = useContext(ThemeContext);
  const { t } = useTranslation();
  const [stdAnomalyData, setStdAnomalyData] = useState({});

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;
    const generalMarketStdAnomaliesUrl = `${BACKEND_API}fundsMeta/generalMarketStdAnomalies`;
    fetch(generalMarketStdAnomaliesUrl, { signal })
      .then((results) => results.json())
      .then((jsonData) => {
        setStdAnomalyData(jsonData);
      })
      .catch(() => {});
  }, []);

  if (!stdAnomalyData.id) {
    return <Loader />;
  }

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <h2>{t('volatility_pca_title')}*</h2>
      </div>
      <h4 style={{ paddingTop: '5px', paddingBottom: '5px' }}>
        {t('explained_variance')}: {stdAnomalyData.explainedVarianceInPercent}%
      </h4>
      <AnomalyChartUPlot data={{ ...stdAnomalyData, id: 'volatility_pca_title' }} yLabel="volatility_pca_chart_title" />
      <p style={{ paddingTop: '5px' }}>
        {t('volatility_pca_explanation')}. {t('volatility_pca_explanation_2')}
      </p>
    </>
  );
}

export default GeneralIndicators;
