// eslint-disable react/destructuring-assignment
import React, { useContext, useEffect } from 'react';
import { ThemeContext } from '../../context/ThemeProvider';
import styles from './styles.module.scss';

const Theme = (props) => {
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    if (theme !== 'dark') {
      document.body.className = '';
    } else {
      document.body.className = `bp3-dark ${styles.darkBackgroundColor}`;
    }
  }, [theme]);

  return <>{props.children}</>;
};

export default Theme;
