import React, { useState, useEffect, useContext } from 'react';
import Ticker from 'react-ticker';
import { Icon, Button, Colors } from '@blueprintjs/core';
import { ThemeContext } from '../../../../context/ThemeProvider';

import { BACKEND_API } from '../../../../global/environment';
import './styles.module.scss';

const GetRatesFromAPI = () => {
  const [rates, setRates] = useState(null);
  const [elements, setElements] = useState('');

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;
    const fundNamesUrl = `${BACKEND_API}stocks/ticker`;
    const fetchAndProcess = () => {
      fetch(fundNamesUrl, { signal })
        .then((results) => results.json())
        .then((jsonData) => {
          setRates(jsonData);
          setElements(
            jsonData.map((e) => {
              let intent = 'primary';
              let icon = 'full-circle';
              let iconSize = 12;
              let backgroundColor = 'rgba(72, 175, 240, 0.3)';

              if (e.AvgPerChange > 0) {
                intent = 'success';
                icon = 'symbol-triangle-up';
                iconSize = window.innerWidth < 1920 ? 15 : 20;
                backgroundColor = 'rgba(61, 204, 145, 0.3)';
              }
              if (e.AvgPerChange < 0) {
                intent = 'danger';
                icon = 'symbol-triangle-down';
                iconSize = window.innerWidth < 1920 ? 15 : 20;
                backgroundColor = 'rgba(255, 115, 115, 0.3)';
              }
              return (
                <span className="tickerItem" key={e.Symbol}>
                  <span style={{ fontWeight: 'bold' }}>{`${e.Symbol} `}</span>
                  <span style={{ fontSize: '12px' }}>{`${e.AvgPrice} `}</span>
                  <Icon
                    icon={icon}
                    iconSize={iconSize}
                    intent={intent}
                    style={{ verticalAlign: 'middle', paddingRight: e.AvgPerChange === 0 ? '5px' : 'inherit' }}
                  />
                  <span style={{ fontSize: '12px', backgroundColor, marginRight: '5px' }}>{`${e.AvgAbsChange}`}</span>
                  <span style={{ fontSize: '12px', backgroundColor }}>{`${e.AvgPerChange}%`}</span>
                  <span style={{ paddingRight: '10px', paddingLeft: '20px' }} />
                </span>
              );
            })
          );
        })
        .catch(() => {});
    };
    fetchAndProcess();

    // const interval = setInterval(() => {
    //   fetchAndProcess();
    // }, 120 * 1000);

    return function cleanup() {
      abortController.abort();
      // clearInterval(interval);
    };
  }, []);
  // A placeholder is needed, to tell react-ticker, that width and height might have changed
  // It uses MutationObserver internally
  return rates ? (
    <p style={{ whiteSpace: 'nowrap', width: '100%' }}>{elements} </p>
  ) : (
    <p style={{ visibility: 'hidden' }}>Placeholder</p>
  );
};

const StockTicker = () => {
  const [move, setMove] = useState(true);
  const [grayColor, setGrayColor] = useState(Colors.GRAY2);
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    setGrayColor(theme === 'dark' ? Colors.GRAY2 : Colors.GRAY4);
  }, [theme]);
  return (
    <div className="ticker-component" style={{ border: '1px', borderColor: grayColor, borderStyle: 'solid none' }}>
      <Button icon={move === false ? 'play' : 'pause'} minimal onClick={() => setMove(!move)} />
      <Ticker move={move} offset="run-in" speed={5} mode="smooth">
        {() => <GetRatesFromAPI />}
      </Ticker>
    </div>
  );
};

export default StockTicker;
