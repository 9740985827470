/* eslint-disable class-methods-use-this */
import React, { useState, useEffect, useContext } from 'react';
import Cookies from 'js-cookie';
import { Button, MenuItem, Divider, Colors, Popover } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import { useTranslation } from 'react-i18next';
import { BACKEND_API } from '../../../../global/environment';
import GlobalContext from '../../../../context/global';
import { ThemeContext } from '../../../../context/ThemeProvider';
import './styles.module.scss';

function FundsSelector() {
  const { t } = useTranslation();
  const { t: tFunds } = useTranslation('fundNames');
  const globalContext = useContext(GlobalContext);
  const { theme } = useContext(ThemeContext);
  const [funds, setFunds] = useState([{ id: 0, name: t('All Funds') }]);
  const [originalFunds, setOriginalFunds] = useState([]);
  const [selectedFund, setSelectedFund] = useState(0);
  const [grayColor, setGrayColor] = useState(Colors.DARK_GRAY5);
  const [isInfoPopOverOpen, setIsInfoPopOverOpen] = useState(Cookies.get('fundSelectorInfoDisplayed') !== 'true');

  useEffect(() => {
    const setFundsState = (jsonData) => {
      setFunds([
        { id: 0, name: t('All Funds') },
        ...jsonData.map((fundData) => {
          return { ...fundData, name: tFunds(fundData.name) };
        }),
      ]);
      if (globalContext.fundAnalysisData.selectedFund) {
        setSelectedFund(globalContext.fundAnalysisData.selectedFund);
      }
    };
    const fetchFundNames = async () => {
      if (originalFunds.length < 1) {
        const fundNamesUrl = `${BACKEND_API}fundsMeta/names`;
        const response = await fetch(fundNamesUrl);
        const jsonData = await response.json();
        setOriginalFunds(JSON.parse(JSON.stringify(jsonData)));
        setFundsState(jsonData);
      } else {
        setFundsState(originalFunds);
      }
    };
    setSelectedFund(0);
    setFunds([{ id: 0, name: t('All Funds') }]);
    fetchFundNames();
  }, [t, globalContext.fundAnalysisData.selectedFund]);

  useEffect(() => {
    setGrayColor(theme === 'dark' ? Colors.DARK_GRAY5 : Colors.LIGHT_GRAY4);
  }, [theme]);

  const handleClick = (item) => {
    setSelectedFund(item.id);
    globalContext.setFundAnalysisProperty('selectedFund', item.id);
  };

  const itemRenderer = (item, { handleClick, modifiers }) => {
    let style = {};
    let intent = 'default';
    let divider = false;

    if (item.id === globalContext.fundAnalysisData.selectedFund) {
      style = { backgroundColor: grayColor, fontWeight: 'bold' };
      intent = 'primary';
    }

    if (item.id === 0) {
      style = { ...style, fontWeight: 'bold' };
      divider = true;
    }

    if (modifiers.active) {
      style = { ...style, backgroundColor: grayColor };
    }

    if (divider) {
      return (
        <div key={`fund_select_${item.id}_div`}>
          <MenuItem
            intent={intent}
            style={style}
            key={item.id}
            text={item.name}
            onClick={handleClick}
            shouldDismissPopover
          />
          <Divider />
        </div>
      );
    }

    return (
      <MenuItem
        intent={intent}
        style={style}
        key={item.id}
        text={item.name}
        onClick={handleClick}
        shouldDismissPopover
      />
    );
  };

  const filterItem = (query, item) => {
    if (!query) {
      return true;
    }
    return item.name.toLowerCase().indexOf(query.toLowerCase()) >= 0;
  };

  return (
    <div className="fundsSelector">
      <Popover
        position={window.innerWidth < 700 ? 'bottom' : 'bottom-left'}
        usePortal={false}
        content={
          <div style={{ padding: '10px' }}>
            <p>{t('fund_selector_info')}</p>
            <Button intent="primary" className="bp3-popover-dismiss">
              {t('close')}
            </Button>
          </div>
        }
        isOpen={isInfoPopOverOpen}
        onClose={() => {
          setIsInfoPopOverOpen(false);
          Cookies.set('fundSelectorInfoDisplayed', true, { expires: 30 });
        }}
      >
        <Select
          items={funds}
          filterable
          resetOnClose
          itemPredicate={filterItem}
          noResults={<MenuItem disabled text="No results." />}
          itemRenderer={itemRenderer}
          onItemSelect={handleClick}
        >
          <Button
            text={funds.find((value) => value.id === selectedFund).name}
            rightIcon="caret-down"
            className="pickFundBtn"
            style={{ animationIterationCount: isInfoPopOverOpen === true ? 'infinite' : 0 }}
          />
        </Select>
      </Popover>
    </div>
  );
}

export default React.memo(FundsSelector);
