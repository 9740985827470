/* eslint-disable react/destructuring-assignment */
import React, { createContext } from 'react';
import useThemeState from '../hooks/useThemeState';

const defaultTheme = 'light';
export const ThemeContext = createContext();

export function ThemeProvider(props) {
  const themeState = useThemeState(defaultTheme);
  return <ThemeContext.Provider value={themeState}>{props.children}</ThemeContext.Provider>;
}
