import React, { useContext } from 'react';
import { ThemeContext } from '../../context/ThemeProvider';
import './styles.scss';

const Loader = () => {
  // const { customThemeClass, setCustomThemeClass } = useState('');
  const { theme } = useContext(ThemeContext);

  return (
    <div className={`loader ${theme === 'dark' ? 'loaderDarkTheme' : ''}`}>
      <div className="loader__bar" />
      <div className="loader__bar" />
      <div className="loader__bar" />
      <div className="loader__bar" />
      <div className="loader__bar" />
      <div className="loader__ball" />
    </div>
  );
};

export default Loader;
