/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-undef */
/* eslint-disable class-methods-use-this */
import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ResponsiveBar } from '@nivo/bar';
import { ThemeContext } from '../../../../context/ThemeProvider';
import { BACKEND_API } from '../../../../global/environment';
import Loader from '../../../../components/loader';

function AllFundsNetValueChart() {
  const { t } = useTranslation();
  const { t: tFunds } = useTranslation('fundNames');
  const { theme } = useContext(ThemeContext);
  const [fundsData, setFundsData] = useState([]);
  const [totalNetValue, setTotalNetValue] = useState(0);
  // eslint-disable-next-line no-undef

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;
    const fundNamesUrl = `${BACKEND_API}fundsMeta/netValue/last`;
    // eslint-disable-next-line no-underscore-dangle
    let _totalNetValue = 0;
    fetch(fundNamesUrl, { signal })
      .then((results) => results.json())
      .then((jsonData) => {
        jsonData.forEach((fundData) => {
          _totalNetValue += fundData.data;
        });
        setFundsData(jsonData);
        setTotalNetValue(_totalNetValue);
      })
      .catch(() => {});
    return function cleanup() {
      abortController.abort();
    };
  }, []);

  const BarComponent = (props) => {
    const nameLength = tFunds(props.data.indexValue).length;
    const labelMaxXWidth = window.innerWidth > 900 ? 400 : 190;
    const labelXWidth = props.width > labelMaxXWidth ? props.width - 16 : nameLength * 7 + props.width + 40;

    return (
      <g transform={`translate(${props.x},${props.y})`}>
        <rect width={props.width} height={props.height} fill={props.color} />
        <rect x={props.width - 5} width={5} height={props.height} fill={props.borderColor} fillOpacity={0.2} />
        <text
          x={labelXWidth}
          y={props.height / 2 - 8}
          textAnchor="end"
          dominantBaseline="central"
          fill="black"
          style={{
            fontWeight: 900,
            fontSize: 15,
          }}
        >
          {}
          {tFunds(props.data.indexValue)}
        </text>
        <text
          x={labelXWidth}
          y={props.height / 2 + 10}
          textAnchor="end"
          dominantBaseline="central"
          //   fill={props.borderColor}
          style={{
            fontWeight: 400,
            fontSize: 13,
          }}
        >
          {`${props.data.value.toLocaleString('en-US')} ${t('MKD')}`}
        </text>
      </g>
    );
  };

  if (!fundsData[0]) {
    return <Loader />;
  }

  return (
    <>
      <h4>
        {t('Net Value (All Funds)')}: {`${totalNetValue.toLocaleString('en-US')} ${t('MKD')}`}
      </h4>
      <ResponsiveBar
        data={fundsData}
        indexBy="id"
        keys={['data']}
        margin={{ top: 5, right: 5, bottom: 70, left: 5 }}
        xScale={{ type: 'point' }}
        yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
        blendMode={theme === 'dark' ? 'normal' : 'multiply'}
        layout="horizontal"
        axisTop={null}
        barComponent={BarComponent}
        enableGridX
        enableGridY={false}
        axisRight={null}
        axisLeft={null}
        enablePoints={false}
        colors={[
          '#B3CFFF',
          '#B3CFFF',
          '#ACC7F9',
          '#ACC7F9',
          '#A5C0F4',
          '#A5C0F4',
          '#9EB9EE',
          '#9EB9EE',
          '#97B1E9',
          '#97B1E9',
          '#8FAAE4',
          '#8FAAE4',
          '#88A3DE',
          '#88A3DE',
          '#819CD9',
          '#819CD9',
          '#7A94D3',
          '#7A94D3',
          '#738DCE',
          '#738DCE',
          '#6C86C9',
          '#6C86C9',
          '#647FC3',
          '#647FC3',
        ]}
        borderColor="#000000"
        colorBy="index"
        useMesh
        axisBottom={{
          orient: 'bottom',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          // legend: 'Net Value (in MKD)',
          legendPosition: 'middle',
          legendOffset: 46,
          tickValues: 3,
          format: (values) => {
            return values.toLocaleString('en-US');
          },
        }}
      />
    </>
  );
}

export default React.memo(AllFundsNetValueChart);
