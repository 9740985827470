/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Button, Intent, Classes, Dialog } from '@blueprintjs/core';
import { CookieInfoDialog } from '../../../hooks/useCookiesToaster';
import styles from './styles.module.scss';

function LegalDialog({ isLegalDialogOpen, setIsLegalDialogOpen }) {
  const { t } = useTranslation();
  const state = {
    autoFocus: true,
    canEscapeKeyClose: true,
    canOutsideClickClose: true,
    enforceFocus: true,
    isOpen: isLegalDialogOpen,
    onClose: () => setIsLegalDialogOpen(false),
    usePortal: true,
  };
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Dialog icon="info-sign" title={t('disclaimer_label')} {...state} className={styles.disclaimerDialog}>
      <div className={[Classes.DIALOG_BODY, styles.dialogBody].join(' ')}>
        <h3>{t('disclaimers_content_title')}</h3>
        <p>{t('disclaimers_content_p1')}</p>
        <p>{t('disclaimers_content_p2')}</p>
        <p>{t('disclaimers_content_p3')}</p>
        <h4>{t('disclaimers_content_p4')}</h4>
        <br />
        <h4>{t('disclaimers_content_p5')}</h4>
        <br />
        <p>{t('disclaimers_content_p6')}</p>
        <p>{t('disclaimers_content_p7')}</p>
        <p>
          {t('disclaimers_content_p8')}
          {/* Our Privacy Policy was created by <a href="https://www.generateprivacypolicy.com/">the Privacy Policy Generator</a>. */}
        </p>
        <h3>{t('consent')}</h3>
        <p>{t('disclaimers_consent_p1')}</p>

        <h3>{t('update')}</h3>

        <p>{t('disclaimer_update_p1')}</p>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button
            intent={Intent.PRIMARY}
            onClick={() => {
              setIsLegalDialogOpen(false);
            }}
          >
            {t('close')}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

const Footer = () => {
  const [isLegalDialogOpen, setIsLegalDialogOpen] = useState(false);
  const [isCookiesDialogOpen, setIsCookiesDialogOpen] = useState(false);
  const { t } = useTranslation();
  return (
    <div className={styles.footer}>
      <Divider vertical="true" />
      <div className={styles.disclaimerText}>
        {t('footer_disclaimer_1')}
        <a href="https://mse.mk/" target="_blank" rel="noopener noreferrer">
          &nbsp;{t('mse')}
        </a>
        . {t('footer_disclaimer_2')}
      </div>
      <div className={styles.horizontalFlex}>
        <a
          href="#"
          onClick={() => {
            setIsLegalDialogOpen(true);
          }}
        >
          {t('disclaimer_label')}
        </a>
        <a
          href="#"
          onClick={() => {
            setIsCookiesDialogOpen(true);
          }}
        >
          {t('cookie_policy_title')}
        </a>
      </div>
      <CookieInfoDialog isCookiesDialogOpen={isCookiesDialogOpen} setIsCookiesDialogOpen={setIsCookiesDialogOpen} />
      <LegalDialog isLegalDialogOpen={isLegalDialogOpen} setIsLegalDialogOpen={setIsLegalDialogOpen} />
    </div>
  );
};

export default Footer;
